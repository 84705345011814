import {
  appleStore,
  arrowWhite,
  phone1,
  phone2,
  playStore,
  workShop,
} from "assets/main";
import React from "react";
import { useNavigate } from "react-router-dom";

const ServicesPartA = ({homeData,loading}) => {
  const navigate = useNavigate();
  const handleClick = (e) => {
    //setDropdownShow(false);
    // Navigate to the 'becomePartner' route
    navigate('/become-partner');
    window.scrollTo(0, 0);
  };
  if (loading) {
    return;
  }
  const data=homeData.component4.company;
  return (
    <>
      <div className="bg-white p-8 lg:p-[60px] xl:px-[120px]">
        <div className="block lg:grid grid-cols-2 gap-4 ">
          <div className="w-full lg:text-start text-center">
            <h1 className="text-4xl font-extrabold max-w-xl text-[#262161] ">
              {/* Our company collection services are */}
              {data.section1.heading}
            </h1>
            <div className="flex text-lg mt-10">
              <div>
              {data.section1.text}
                {/* <div className="mt-16">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim.
                </div> */}
              </div>
            </div>
            <div className="mt-16">
              <button
                type="button"
                className="bg-[#F69525] pl-10 pr-10 text-center font-bold py-5 relative  rounded-full"
                onClick={handleClick}
              >
                Register
              </button>
            </div>
          </div>
          <div className=" w-full   mx-0">
            <div className="image-container">
              <img 
                className="bg-white p-4 shadow-xl"
                src={data.section1.icon}
                alt="workshop1"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesPartA;
