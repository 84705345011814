import React from "react";

import { play, arrowWhite, client } from "assets/main";

const Stats = ({homeData,loading}) => {
  if (loading) {
    return ;
  }
  return (
    <>
      <div className="bg-gray-300 p-8 lg:p-16 lg:py-28 lg:px-[110px]">
        <div className="bg-[#262161] text-center lg:text-start lg:flex p-8 rounded-xl lg:p-[80px] ">
          <div className="lg:w-1/3 lg:mt-[-40px]">
            <div className="lg:text-4xl text-xl text-white font-extrabold w-[100%] ">
             {homeData.component7.heading} {/* Stats & Change brought to the world */}
            </div>
            <div className="mt-8 lg:mt-12 relative">
              <img src={homeData.component7.image} alt="client" className="w-11/12" />
              <div className="rounded-full px-8 pr-7 py-7 bottom-6 left-6 absolute bg-[#F69525] z-[1020]">
                <img src={play} alt="play" />
              </div>
            </div>
          </div>
          <div className="lg:w-2/3 lg:pl-[97px]">
            <div className="text-white text-[12px] mt-8 lg:mt-[-40px] lg:text-lg lg:w-[100%]">
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ulamco. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ulamco. Lorem ipsum dolor sit amet, consectetur */}
              {homeData.component7.text}
            </div>
            <div className="mt-8 lg:mt-14">
              <div className="lg:flex lg:mb-20 justify-between">
                <div>
                  <div className="text-white text-4xl lg:text-5xl font-bold">
                    {/* 200K */}{homeData.component7.stats1.value}
                  </div>
                  <div className="text-sm lg:text-sm text-[#F69525] font-semibold">
                    {/* Clients */}{homeData.component7.stats1.key}
                  </div>
                </div>
                <div className="mt-8 lg:mt-0">
                  <div className="text-white text-4xl lg:text-5xl font-bold">
                    {/* 97% */}{homeData.component7.stats2.value}
                  </div>
                  <div className="text-sm lg:text-sm text-[#F69525] font-semibold">
                    {/* Clients Satisfaction */}{homeData.component7.stats2.key}
                  </div>
                </div>
                <div />
              </div>
              <div className="lg:flex lg:mb-20 justify-between">
                <div className="mt-8 lg:mt-0">
                  <div className="text-white text-4xl lg:text-5xl font-bold">
                    {/* 89% */}{homeData.component7.stats3.value}
                  </div>
                  <div className="text-sm lg:text-sm text-[#F69525] font-semibold">
                    {/* Annual Clients */}{homeData.component7.stats3.key}
                  </div>
                </div>
                <div className="mt-8 lg:mt-0 lg:ml-10">
                  <div className="text-white text-4xl lg:text-5xl  font-bold">
                    {/* 10B */}{homeData.component7.stats4.value}
                  </div>
                  <div className="text-sm lg:text-sm text-[#F69525] font-semibold">
                    {/* Lorem Ipsum Color Dior */}{homeData.component7.stats4.key}
                  </div>
                </div>
                <div />
              </div>
              <div className="mt-8">
                <button
                  type="button"
                  className="bg-[#F69525] pl-10 pr-24 font-bold py-1 w-[88%] lg:py-1 relative  rounded-full lg:w-[45%]"
                >
                  Become a Partner
                  <div className="absolute  lg:p-3 p-3 right-1 bottom-1 rounded-full bg-[#262161]">
                    <img src={arrowWhite} alt="arrow" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stats;
