import React, { useState } from "react";

const FAQSection = ({homeData,loading}) => {
  // State to manage the visibility of each answer
  const [showAnswers, setShowAnswers] = useState([true, false, false, false]);

  // Function to toggle the visibility of a specific answer
  const toggleAnswer = (index) => {
    const newShowAnswers = showAnswers.map((value, i) => (i === index ? !value : false));
    setShowAnswers(newShowAnswers);
  };
  if (loading) {
    return ;
  }
  
      
  const faqData=homeData.component10;
  const faqs = faqData.questions;/* Object.keys(faqData)
    .filter(key => key.includes('q'))
    .map(key => faqData[key]); */
    console.log('reviews'+JSON.stringify(faqs));
  //console.log('faqs'+JSON.stringify(faqs));
  //return;
   return (
    <>
      <div className="p-8  lg:px-16">
        <div className="bg-[#1E1E1E] rounded-2xl p-8 lg:px-44 py-16">
          <div className="text-center">
            <h1 className="text-[#F69525] text-3xl pb-8 font-extrabold">
              {homeData.component10.heading}
            </h1>
            <p className="text-lg text-[#FFFFFF] pb-24">
            {homeData.component10.sub_heading}
            </p>
          </div>

          {faqs.map((faq, index) => (
            <div key={index} className="py-5 group border-b-2 border-gray-500">
              <div
                className={`flex text-[white] ${showAnswers[index] ? "text-[#F69525]" : ""
                  } mb-4 items-center`}
                onClick={() => toggleAnswer(index)}
              >
                <div className="pr-2.5">
                  <svg
                    className={`w-4 h-4 ${showAnswers[index] ? "hidden" : "block"
                      }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 18"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 1v16M1 9h16"
                    />
                  </svg>
                  <svg
                    className={`w-4 h-4 ${showAnswers[index] ? "block" : "hidden"
                      }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 2"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 1h16"
                    />
                  </svg>
                </div>
                <p className=""> {faq.question}</p>
              </div>
              <p
                className={`text-[#F69525] ${showAnswers[index] ? "block" : "hidden"
                  }`}
              >
                {faq.answer}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  ); 
};

export default FAQSection;

// Sample data for FAQ questions and answers
/* const faqData = [
  {
    question: "Do you know everything that matters about scrap?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "It’s worth knowing about scrap for everyone.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Do you know everything that matters about scrap?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Do you know everything that matters about scrap?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
]; */
