import { getHomePage, getNavbar, getSocialMedia } from "assets/firebase";
import { WhatsAppIcon, logoImage, flagBH, flagSA, linkdenIcon, tiktokIcon, instgramIcon } from "assets/main";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
const Header = () => {
  const location = useLocation();

  const [dropdownShow, setDropdownShow] = useState(false);

  const scrollWin = (x, y) => {
    window.scrollBy(x, y);
  };
  const [navbarData, setNavbarData] = useState(null);
  const [socialData, setSocialData] = useState(null);
  const [headerData, setHeaderData] = useState(null); // State to hold retrieved home data
  const [loading, setLoading] = useState(true); // Loading state
  const [inputValue, setInputValue] = useState('');
  let  region = localStorage.getItem("region");
  /*  const changeRegion = () => {
     setRegion(region === 'sa' ? 'sa' : 'bh' );
   }; */


  const [dropdownVisible, setDropdownVisible] = useState(false);

  const changeRegion = (newRegion) => {
    //setRegion(newRegion);
    // Set the variable "region" in localStorage
    localStorage.setItem("region", newRegion);

    // Get the variable "region" from localStorage
    //let region = localStorage.getItem("region");
    setDropdownVisible(false);

  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  useEffect(() => {
    // Fetch data when the component mounts

    const fetchData = async () => {
      try {

        const navBar = await getNavbar();
        const data = await getHomePage();
        const social = await getSocialMedia();
        setNavbarData(navBar.response);
        setSocialData(social.response);
        setHeaderData(data.response);
        setLoading(false);
        console.log(data.response.component1);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  if (loading) {
    return;
  }

  const logObj = {
    "/": {
      bg: "bg-logo text-white !h-[53rem]",
      backgroundImage: headerData.component12.home_background1,//"./assets/images/homeBg.png",
      heading: (
        <>
          <div className="mt-32 text-white mb-4 lg:mb-0 text-center lg:text-start text-4xl lg:text-[3.625rem] lg:leading-[70px] font-bold">
            {/*  Quickest &<br />
            <span className="text-[#F69525] mr-4">Safest</span>
            Receiving  */}

            <span dangerouslySetInnerHTML={{ __html: headerData.component1.heading }} />
          </div>
          <div className="text-white text-center mt-2 lg:text-start text-[12px] lg:w-[350px] font-thin">
            {/* Take the complexity out of lorem ipsum scrap receiving services orem
            ipsum scrap receiving services */}
            <span dangerouslySetInnerHTML={{ __html: headerData.component1.sub_heading }} />

          </div>
          <div className="bg-[rgba(246,149,37,0.1)] backdrop-blur-[50px]  rounded-[25px] mt-16 lg:w-[42%] p-8">
            {/*  <label
              htmlFor="first_name"
              className="block text-center lg:text-start mb-2 text-sm font-medium text-white"
            >
              How to partner with eBin?
            </label> */}
            <div className="lg:flex text-center lg:text-start justify-between items-center relative">
              {/*  <input
                type="phone"
                id="first_name"
                className="bg-gray-50 mr-3 mb-4 lg:mb-0  border border-gray-300 text-black text-lg font-bold rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full pl-12 px-4 p-2.5"
                placeholder={headerData.whasapp_hint}
                value='How to partner with eBin'
                onChange={(e) => setInputValue(e.target.value)}
              /> */}
              <label
                htmlFor="first_name"
                className="block text-center lg:text-start lg:text-xl   text-white"
              >
                How to partner with eBin?
              </label>

              {/*  <img
                src={WhatsAppIcon}
                alt="WhatsAppIcon"
                className="absolute h-6 w-6 top-3 left-4"
              /> */}

              <button
                type="button"
                className="bg-[#F69525] px-7 py-3 text-black rounded-full lg:w-[30%]"
                onClick={() => {
                  const phoneNumber = '+97335390649';
                  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(inputValue)}`;
                  window.open(whatsappUrl, '_blank');
                }}
              >
                Message
              </button>
            </div>
          </div>
        </>
      ),
    },
    "/learn-more": {
      bg: "bg-logo-learn-More text-white !h-[55rem]",
      backgroundImage: headerData.component12.learn_more_image,

      heading: (
        <>
          <div className="text-white mt-20 mb-0 lg:mb-0 text-center lg:text-center text-3xl lg:text-7xl lg:leading-normal font-bold">
            {/* Learn About
            <br />
            <span className="text-[#F69525]">Recyclables</span>
            Items */}
            <span dangerouslySetInnerHTML={{ __html: headerData.component1.learn_more_heading }} />

          </div>
          <div className="text-[16px] text-white text-center mt-8 lg:text-center">
            <span dangerouslySetInnerHTML={{ __html: headerData.component1.learn_more_text }} />

          </div>
        </>
      ),
    },
    "/shop": {
      bg: "bg-logo-services text-white !h-[65rem]",

      heading: (
        <>

        </>
      ),
    },
    "/become-partner": {
      bg: "bg-logo-services text-white lg:!h-[65rem]",
      backgroundImage: headerData.component12.become_partner_image,
      heading: (
        <>
          <div className="text-white lg:mt-20 mt-40 mb-0 lg:mb-0 text-center lg:text-center text-4xl lg:text-[4.625rem] lg:leading-[90px] font-bold">
            <span dangerouslySetInnerHTML={{ __html: headerData.component1.become_partner_heading }} />
            {/* Book Your
            <br />
            <span className="text-[#F69525] mr-4">Services</span>
            Todays */}
          </div>
          <div className="text-[16px] text-white text-center lg:mt-8 mt-14 lg:pl-15 lg:text-center font-base">
            <span dangerouslySetInnerHTML={{ __html: headerData.component1.become_partner_text }} />
          </div>
        </>
      ),
    },
    "*": { bg: "" },
  };

  return (
    <>
      <div className="row bg-[#F69525] pt-2">

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="col-6 flex" style={{ textAlign: 'left', paddingLeft: '50px', }}>
            {socialData.instagram.status === 'true' &&
              <a href={socialData.instagram.url}>
                <img style={{ width: '30px', height: '30px', marginRight: '3px' }} src={socialData.instagram.icon} alt="" />
              </a>
            }
            {socialData.tiktok.status === 'true' &&
              <a href={socialData.tiktok.url}>
                <img style={{ width: '30px', height: '30px', marginRight: '3px' }} src={socialData.tiktok.icon} alt="" />
              </a>
            }
            {socialData.linkden.status === 'true' &&
              <a href={socialData.linkden.url}>
                <img style={{ width: '30px', height: '30px', marginRight: '3px' }} src={socialData.linkden.icon} alt="" />
              </a>
            }
            {socialData.facebook.status === 'true' &&
              <a href={socialData.facebook.url}>
                <img style={{ width: '30px', height: '30px', marginRight: '3px' }} src={socialData.facebook.icon} alt="" />
              </a>
            }
            {socialData.twitter.status === 'true' &&
              <a href={socialData.twitter.url}>
                <img style={{ width: '30px', height: '30px', marginRight: '3px' }} src={socialData.twitter.icon} alt="" />
              </a>
            }
            {socialData.youtube.status === 'true' &&
              <a href={socialData.youtube.url}>
                <img style={{ width: '30px', height: '30px', marginRight: '3px' }} src={socialData.youtube.icon} alt="" />
              </a>
            }

          </div>
          {/* <div className="col-6" style={{ textAlign: 'right', paddingRight: '50px',paddingTop:'5px',paddingBottom:'5px' }}>
            <label htmlFor="" style={{verticalAlign: "top", marginRight: "4px"}}>Region</label>
            <button type="button" className="" onClick={changeRegion}>
              
              {region === 'bh' ? <img src={flagBH} alt="" style={{width: '50px', height: '25px'}} /> : <img src={flagSA} alt="" style={{width: '50px', height: '25px'}} />}
           
            </button>
          </div> */}
          <div className="col-6" style={{ textAlign: 'right', paddingRight: '50px', paddingTop: '5px', paddingBottom: '5px' }}>
            <label htmlFor="" style={{ verticalAlign: "top", marginRight: "4px" }}>Region</label>
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <button type="button" className="" onClick={toggleDropdown}>
                {region === 'bh' ? <img className="rounded-2xl" src={flagBH} alt="BH" style={{ width: '50px', height: '25px' }} /> : <img className="rounded-2xl" src={flagSA} alt="SA" style={{ width: '50px', height: '25px' }} />}
              </button>
              {dropdownVisible && (
                <div style={{ position: 'absolute', top: '100%', left: '0', zIndex: '1' }}>
                  <button type="button" onClick={() => changeRegion('bh')}>
                    <img className="rounded-2xl" src={flagBH} alt="BH" style={{ width: '50px', height: '25px' }} />
                    {/* {region === 'bh' && <span>&#10003;</span>} */}
                  </button>
                  <button type="button rounded-lg" onClick={() => changeRegion('sa')}>
                    <img className="rounded-2xl" src={flagSA} alt="SA" style={{ width: '50px', height: '25px' }} />
                    {/* {region === 'sa' && <span>&#10003;</span>} */}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <header
        className={`${logObj[location.pathname]?.bg
          ? logObj[location.pathname]?.bg
          : "!text-black "
          } p-8 !py-12 lg:p-16 xl:p-32`}
        style={{ backgroundImage: `url(${logObj[location.pathname]?.backgroundImage})` }}
      >

        <nav className="border-gray-200  py-2.5 ">
          <div className="relative flex flex-wrap justify-between items-center mx-auto ">
            <div className="lg:hidden w-10"></div>
            <Link to="/">
              <div className="lg:w-[85%] lg:ml-[-4px] ml-16 w-[70%]">
                <img src={logoImage} alt="logo" />
              </div>
            </Link>
            <div className="flex items-center lg:order-2">
              <button
                onClick={() => {
                  setDropdownShow(!dropdownShow);
                }}
                data-collapse-toggle="mobile-menu-2"
                type="button"
                className="inline-flex items-center p-2 ml-1 text-sm text-white  rounded-lg lg:hidden "
                aria-controls="mobile-menu-2"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className={`${dropdownShow ? "hidden" : "block"} w-6 h-6  `}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 4 15"
                >
                  <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                </svg>
                <svg
                  className={`${dropdownShow ? "block" : "hidden"} w-6 h-6`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              <a
                href="https://ebincompany.com/portal/"
                rel="noopener noreferrer"
                className="text-[12px] bg-[#F69525] text-black hidden lg:block px-7 py-3 rounded-full"
              >
                Goto Portal
              </a>

            </div>

            <div
              className={`${dropdownShow
                ? "block absolute backdrop-blur-md top-20 p-5"
                : "hidden"
                } justify-between  items-center w-full lg:flex lg:w-auto lg:order-1`}
              id="mobile-menu-2"
            >
              <ul className="text-[16px] flex flex-col mt-4 pt font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                <li className=" p-2.5">
                  <Link
                    to={navbarData.home.url}
                    className="after:absolute after:ml-[-30px] after:h-[2px] after:bg-orange-700 after:w-[2%]  after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                    onClick={() => setDropdownShow(false)}
                  >
                    {/* Home */}
                    {navbarData.home.title}
                  </Link>
                </li>

                <li className="p-2.5">
                  <a
                    href={navbarData.shop.url}
                    rel="noopener noreferrer"
                    className="after:absolute after:ml-[-25px] after:h-[2px] after:bg-orange-700 after:w-[1.5%]  after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                    onClick={() => setDropdownShow(false)}
                  >
                    {/* Shop */}
                    {navbarData.shop.title}
                  </a>
                </li>
                <li className="p-2.5">
                  <Link
                    to={navbarData.lar.url}
                    className="after:absolute after:ml-[-110px] after:h-[2px] after:bg-orange-700 after:w-[5%]  after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                    onClick={() => setDropdownShow(false)}
                  >
                    {/* Learn About Recyclables */}
                    {navbarData.lar.title}
                  </Link>
                </li>
                <li className="p-2.5 ">
                  <Link
                    to={navbarData.partner.url}
                    className="after:absolute after:ml-[-80px] after:h-[2px] after:bg-orange-700 after:w-[3%]  after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                    onClick={() => setDropdownShow(false)}
                  >
                    {/* Become a Partner */}
                    {navbarData.partner.title}
                  </Link>
                </li>
                <li>
                  <a
                    href={navbarData.portal.url}
                    rel="noopener noreferrer"
                    className="bg-[#F69525] lg:hidden px-5 py-3 rounded-full"
                    onClick={() => setDropdownShow(false)}
                  >
                    {/* Goto Portal */}
                    {navbarData.portal.title}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {logObj[location.pathname]?.heading}
        {["/", "/learn-more"].includes(location.pathname) ? (
          <div className="mt-20  hover:animate-bounce text-white text-center">
            <div
              className="border-2 inline rounded-full p-4 pb-6"
              onClick={() => scrollWin(0, 5000)}
            >
              <span className="font-bold">|</span>
            </div>
            <div className="mt-8 text-[14px] font-bold">SCROLL DOWN</div>
          </div>
        ) : null}
      </header>
    </>
  );
};

export default Header;
