import React, { useState } from "react";
import {
  image1,
  image2,
  Rectangle1,
  Rectangle2,
  projectManager,
} from "assets/main";
import { useNavigate } from 'react-router-dom';
const CompanyServices = ({homeData,loading}) => {
  const navigate = useNavigate();
  const handleClick = (e) => {
    //setDropdownShow(false);
    // Navigate to the 'becomePartner' route
    navigate('/become-partner');
    window.scrollTo(0, 0);
  };
  const handleClick2 = (e) => {
    //setDropdownShow(false);
    // Navigate to the 'becomePartner' route
    navigate('/become-partner');
    window.scrollTo(0, 0);
  };
  if (loading) {
    return;
  }
  const data=homeData.component4.company;
  return (
    <>
      <div className="bg-[#FFF7EE] h-full p-8 lg:p-[60px] xl:px-[120px]">
        <div className=" lg:flex justify-between ">
          <div className=" w-full  mx-0">
            <div className="mb-4 lg:mr-20 lg:mb-0">
              <img className="" src={data.section2.icon} alt="Rectangle1" />
            </div>
          </div>
          <div className="w-full text-center lg:text-start">
            <h1 className="text-4xl font-extrabold max-w-xl text-[#262161] ">
              {/* Our company trusted sponsors are */}
              {data.section2.heading}
            </h1>
            <div className="flex text-lg mb-16 mt-8">
              <div>
              {data.section2.text}
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2  " >
              <img src={data.section2.sponsor_img1} alt="image1" class="object-contain h-full w-full"/>
              <img className="py-12 bg-white" src={data.section2.sponsor_img2} alt="image2" class="object-contain h-full w-full" />
              <img src={data.section2.sponsor_img3} alt="image1" class="object-contain h-full w-full" />
            </div>
            <div className="">
              <button
                type="button"
                className="bg-[#F69525] pl-10 pr-10 text-center font-bold py-5 relative  rounded-full"
                onClick={handleClick}
              >
                Register
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white p-8 lg:p-[60px] xl:px-[120px]">
        <div className="block lg:grid grid-cols-2 gap-4 ">
          <div className="w-full text-center lg:text-start">
            <h1 className=" text-2xl lg:text-4xl font-extrabold max-w-xl text-[#262161] ">
            {data.section3.heading}{" "}
            </h1>
            <div className="flex text-lg font-normal mt-10">
              <div>
              {data.section3.text}
                {/* <div className="mt-16">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim.
                </div> */}
              </div>
            </div>
            <div className="mt-16">
              <button
                type="button"
                className="bg-[#F69525] pl-10 pr-10 text-center font-bold py-5 relative  rounded-full"
                onClick={handleClick2}
             >
                Register
              </button>
            </div>
          </div>
          <div className="w-full mx-0">
            <div className="image-container">
              <img
                className="bg-white p-4 shadow-xl "
                src={data.section3.icon}
                alt="Rectangle2"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="p-8 text-center lg:text-start lg:p-16">
        <div className="lg:flex justify-between">
          <div className=" lg:w-1/3  bg-orange-400 rounded-2xl p-8 pb-0">
            <div className="flex justify-between">
              <div className="lg:w-[12rem] text-start">
                <h4 className="lg:text-[10px] text-[6px] text-white text-base font-bold">
                {data.section4.card_left.text1}
                </h4>
                <div className="mt-6 text-white lg:text-3xl text-1xl font-black font-['Satoshi']">
                {data.section4.card_left.text2}
                </div>
                <div
                  className="lg:text-[15px] text-[7px] text-white mt-2 font-bold font-['Inter']
                "
                >
                  {data.section4.card_left.text3}
                </div>
              </div>
              <div>
                <img src={data.section4.card_left.image} alt="projectManager" />
              </div>
            </div>
          </div>
          <div className="lg:pl-16  mt-8 lg:mt-0 lg:w-2/3">
            <div className="bg-[#F3F3F3] rounded-xl p-12 py-16">
              <div className="lg:flex justify-between">
                <div>
                  <h1 className="lg:max-w-[2rem] font-extrabold text-4xl text-[#2D2D2D]">
                  {data.section4.card_right.heading}
                  </h1>
                  <p className="lg:max-w-[10rem] mt-10 text-[#A7ABB6]">
                  {data.section4.card_right.text}
                  </p>
                </div>
                <div className="text-center lg:flex  lg:text-start justify-center items-center">
                  <div className="lg:flex justify-between">
                    <div className="mt-4 lg:mt-0">
                      <div className="text-5xl font-bold text-orange-400">
                      {data.section4.card_right.value1}
                      </div>
                      <p className="lg:max-w-[10rem] mt-6 text-[#A7ABB6]">
                      {data.section4.card_right.key1}
                      </p>
                    </div>
                    <div className="lg:ml-10 mt-4 lg:mt-0">
                      <div className="text-5xl font-bold text-orange-400">
                      {data.section4.card_right.value2}
                      </div>
                      <p className="lg:max-w-[10rem] mt-6 text-[#A7ABB6]">
                      {data.section4.card_right.key2}
                      </p>
                    </div>
                    <div className="lg:ml-10 mt-4 lg:mt-0">
                      <button
                        type="button"
                        className="bg-[#F69525] pl-10 pr-10 text-center text-white font-bold py-5 relative  rounded-full"
                        onClick={() => {
                          const contactFormSection = document.getElementById('contactForm');
                      
                          if (contactFormSection) {
                            contactFormSection.scrollIntoView({ behavior: 'smooth' });
                          }
                        }}
                     >
                        Contact Us
                        
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyServices;
