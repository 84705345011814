import React from 'react';
import { term } from "assets/main";
import checkImage from "../../assets/images/check.png";
const ThankYouPage = () => {
  return (
    <section id="mainhead">
    <div className='p-20 text-center flex flex-col items-center'>
        <img src={checkImage} className='img-fluid mb-4' alt="" />
      <h1 className='text-2xl font-bold mb-4'>Thank You!</h1>
      <p className='text-[#A3A3A3] '>The form was submitted successfully.</p>
      {/* You can add additional content or styling here */}
    </div>
    </section>
  );
};

export default ThankYouPage;
/* The for was submitted successfully. */




