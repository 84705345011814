import React from "react";
import { Link } from "react-router-dom";

import { telephone, email, map } from "assets/main";
import {saveContactUs} from "assets/firebase";
import { useEffect, useState } from 'react';
const Contact =  ({homeData,loading}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    notify: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const result = await saveContactUs(formData);
    if (result.success) {
      window.alert('Thank you for reaching us!');
     // console.log('Form submitted successfully. Document ID:', result.docId);
      setFormData({ name: '', email: '', message: '',notify: 'true' }); // Reset form data
    } else {
      console.error('Error submitting form:', result.error);
    }
  };
  if (loading) {
    return ;
  }
  const address=homeData.component11.map;
  const getMapsUrl = (address) => {
    const encodedAddress = encodeURIComponent(address);
    return `https://www.google.com/maps/embed/v1/place?q=${encodedAddress}&key=AIzaSyDlB0I1iqgrnzCShDjvbS0byRDv0Haqtwg`;
    // Replace 'YOUR_API_KEY' with your actual Google Maps API key
  };
  const bimage = homeData.component12.home_background3;
 // console.log(homeData.component11);
  return (
    <>
      
      <div className="" id="contactForm" style={{ backgroundImage: `url(${bimage})`, height:'500px',backgroundSize: 'cover',
  backgroundPosition: 'center', backgroundRepeat:'no-repeat',  }}>
      {/*   <iframe
          src={getMapsUrl(address)}//"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3572.650801041819!2d50.03652418057122!3d26.434743221017136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49fdc042a290e3%3A0x5d13ed061a2127d9!2sBahrain%20St%2C%20Al%20Faiha%2C%20Dammam%2032441%2C%20Saudi%20Arabia!5e0!3m2!1sen!2s!4v1700063319086!5m2!1sen!2s"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          className="map"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="googleMap"
        ></iframe> */}
      </div>
      <div className="ml-6  lg:px-44 lg:py-16 relative mt-16 lg:ml-12">

        <div className="flex hidden lg:block">
          <img className="lg:p-2 lg:h-8 lg:w-8" src={telephone} alt="telephone" />
          <Link className="text-[#010002] font-bold">{homeData.component11.phone}</Link>
        </div>
         <div className="flex mt-3 hidden lg:block">
          <img className="p-2 h-8 w-8" src={email} alt="email" />
          <Link className="text-[#010002] font-bold ">
          {homeData.component11.email}
          </Link>
        </div>
        <div className="flex mt-3 hidden lg:block">
          <img className="p-2 h-8 w-8" src={map} alt="map"/>
          <Link className="text-[#010002] font-bold hidden lg:block">
          {homeData.component11.address}
          </Link>
        </div>
        <div className="absolute lg:right-20 shadow-xl bg-[#FFFFFF]  border-2 border-gray-200 rounded-3xl lg:w-[479px] py-8 py-16 px-16 mx-auto  max-w-screen-md  bottom-10">
          <h2 className="mb-4 lg:text-4xl  font-extrabold text-[#262161]">
            Contact Us
          </h2>
          <form action="#" className="space-y-8" onSubmit={handleFormSubmit}>
            <div>
              <input
                type="name"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className=" block w-full p-2.5 group border-b-2 outline-none ring-transparent border-gray-500 "
                placeholder="Full name"
                required=""
              />
            </div>
            {/* <input type="hidden" value={false} name="notify" /> */}
            <div>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className=" block w-full p-2.5 group border-b-2 border-gray-500 "
                placeholder="Email Address"
                required=""
              />
            </div>
            <div>
              <input
                type="text"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className=" block w-full p-2.5 group border-b-2 border-gray-500 "
                placeholder="Tell us about it."
                required=""
              />
            </div>
            <button
              type="submit"
              className="py-3 px-5 bg-[#F69525] w-full font-bold text-center text-black rounded-full"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default Contact;
