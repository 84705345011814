import React from "react";

const PageNotFound = () => {
  return (
    <>
      <div className=" text-center p-16">
        <h1 className="text-9xl font-extrabold text-[#F69525]">404</h1>
        <div className="text-bold">
          <p>Sorry. the content you're looking for doesn't exist.</p>
          <p>Either it was removed, or you mistyped the link </p>
        </div>
        <div className="pt-8">
          <button
            type="button"
            className="bg-[#F69525] m-8 px-5 py-3 rounded-full"
          >
            Goto Home
          </button>

          <button
            type="button"
            className="text-[#F69525]  bg-white border-2 px-5 py-3 rounded-full"
          >
            Contact Us
          </button>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
