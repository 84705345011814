import React, { useEffect, useRef, useState } from "react";
import {
  botle,
  other,
  box,
  close,
  open,
  can,
  wallpaper3,
  wallpaper2,
  wallpaper1,
} from "assets/main";
import Lottie from 'lottie-react';
import spinningArrowsAnimation from "assets/lottie/animation.json";
import { Link, Route } from "react-router-dom";

const About = ({ homeData, loading }) => {
  let ref = useRef(null);
  const [goalIndex, setGoalIndex] = useState(0);
  useEffect(() => {
    console.log("ref", ref);
    if (ref.current) {
      ref.current.click();
      debugger;
      //   console.log("ref.current", ref.current);
    }
  }, [ref]);
  if (loading) {
    return (
      <div className="w-[200px] h-[600px] m-auto mt-5 mb-5">

        <div>
          <Lottie animationData={spinningArrowsAnimation} />
        </div>
      </div>
    );
  }


  console.log('about' + JSON.stringify(homeData.component2.plastic));
  //const about_text=aboutdata.about_text;
  /* console.log('zeeshan'+homeData.component1.about_text); */
  return (
    <>
      <div className="bg-white p-8 lg:p-[60px] xl:px-[120px] w-full">
        <div className="mt-10 text-2xl lg:text-start lg:text-4xl flex justify-center font-extrabold mb-8 lg:mb-28">
          <div className="text-center text-[#262161] lg:w-[45%] w-[100%]">
            {homeData.component1.about_text}
          </div>
        </div>
        <div className="w-full grid-cols-2 mb-8 lg:mb-0 gap-1  lg:grid">
          <div className="-mr-1 text-red mb-8 lg:mb-0">
            {[
              {
                name: "Metal Recyclable",
                icon: homeData.component2.metal_icon,
              },
              {
                name: "Plastic Recyclable",
                icon: homeData.component2.plastic_icon,
              },
              {
                name: "Paper Recyclable",
                icon: homeData.component2.paper_icon,
              },
              {
                name: "Others Recyclable",
                icon: homeData.component2.other_icon,
              },
            ].map(({ name, icon }, index) => (
              <div
                onMouseOver={() => {
                  setGoalIndex(index);
                }}
                key={name}
                className={` border-l pl-8 lg:pl-12 ${name === "Others Scrap" ? "" : "pb-8 lg:pb-12"
                  } ${index === goalIndex ? "border-l-4" : "hover:border-l-4"
                  }   border-indigo-800 `}
              >
                <div className="flex lg:w-80 justify-between items-center">
                  <div
                    className={`p-2  ${index === goalIndex ? "bg-[#F69525]" : "bg-gray-400"
                      }  group-hover:bg-[#F69525]   rounded-lg `}
                  >
                    <img src={icon} alt="can" className="h-6 w-6" />
                  </div>

                  <div
                    className={`${index === goalIndex ? "text-black" : "text-gray-400"
                      } text-xl lg:text-2xl lg:ml-4  group-hover:text-black font-bold`}
                  >
                   <Link to="/learn-more" >{name}</Link> 
                  </div>
                  <span
                    className={`${index === goalIndex ? "hidden" : ""
                      } group-hover:hidden`}
                  >
                    <img
                      src={close}
                      alt="close"
                      className="h-2 w-2 lg:h-auto lg:w-auto"
                    />
                  </span>
                  <span
                    className={`${index === goalIndex ? "block" : "hidden"
                      }   group-hover:block`}
                  >
                    <img
                      src={open}
                      alt="open"
                      className="h-2 w-2 lg:h-auto lg:w-auto"
                    />
                  </span>
                </div>
                <div
                  className={`${index === goalIndex ? "block" : "hidden"
                    }  mb-2 lg:text-xl lg:w-96 pt-6 font-medium  group-hover:block`}
                >
                  
                  {/* {homeData.component2.other } */}
                  {index === 0 && homeData.component2.metal}
                  {index === 1 && homeData.component2.plastic}
                  {index === 2 && homeData.component2.paper}
                  {index === 3 && homeData.component2.other}
               
                </div>

              </div>
            ))}
          </div>
          <div className="grid grid-cols-2 lg:gap-8 gap-2 mb-16">
            <div>
              <div className="relative">
                <img src={homeData.component2.image1} className="lg:mb-10 mb-2 lg:!h-[12rem]" alt="wallpaper1" />
                <div className="absolute bg-[#F69525]  lg:w-[100px] w-[50px] lg:-left-7 lg:-top-7 -left-4 -top-4 lg:h-[100px] h-[50px] lg:rounded-[25px] rounded-[15px] bg-opacity-60"></div>
              </div>
              <div>
                <img src={homeData.component2.image2} className="lg:!h-[12rem]" alt="wallpaper1" />
              </div>
            </div>
            <div className="">
              <div className="lg:h-[425px]">
                <img
                  className="w-full h-[100%]"
                  src={homeData.component2.image3}
                  alt="wallpaper"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
