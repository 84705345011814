
import { getTermsPage } from "assets/firebase";
import { bgPrivacy } from "assets/main";
import React, { useEffect, useState } from "react";
import Lottie from 'lottie-react';
import spinningArrowsAnimation from "assets/lottie/animation.json";
import he from 'he';
const PrivacyPolicy = () => {
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {

        const data = await getTermsPage();

        setPageData(data.response); // Set the retrieved data to state
        setLoading(false);

      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);


  if (loading) {
    return (
      <div className="w-[200px] m-auto mt-5 mb-5">

        <div>
          <Lottie animationData={spinningArrowsAnimation} />
        </div>
      </div>
    );
  }
  const decodedContent = he.decode(pageData.privacy_policy);
  //console.log('terms'+JSON.stringify(pageData));
  return (
    <>
      <div className=" lg:!h-[27rem] !h-[40rem] rounded-[50px] lg:w-[84%] w-[100%] lg:ml-[9%]"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)), url(${pageData.privacy_background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
      </div>

      <div className=" lg:!h-[27rem]  px-16 lg:mt-[-300px] mt-[-530px] text-white text-center lg:text-4xl text-3xl lg:text-[2.625rem] lg:leading-[3.1rem] font-bold">
        {/*  We Care About<br />Your
                <span className="text-[#F69525] lg:mr-4"> Privacy Policy</span> */}
        {/* {pageData.privacy_heading} */}
        <span dangerouslySetInnerHTML={{ __html: pageData.privacy_heading }} />
        <div className="text-[16px] text-[#fff] text-center lg:mt-[50px] mt-[30px] lg:w-auto  lg:ml-[40px] ml-[-10px] leading-[2rem] font-normal">
          {pageData.privacy_sub_heading}
        </div>

      </div>


      {/* Privacy Data */}
      <div className="max-w-4xl mx-auto p-8 p-2 bg-[#FFF] lg:mt-[-100px] mt-20">
        <div className="px-[1px] items-center lg:text-left text-center lg:text-[20px] text-[#282828] font-medium">
          {/* {pageData.privacy_policy} */}
          <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: decodedContent }} />

        </div>

      </div>
    </>
  );
};

export default PrivacyPolicy;
