import React from "react";
import {
  arrowWhite,
  appleStore,
  playStore,
  phone1,
  phone2,
  workShop,
  arrow,
  can,
} from "assets/main";


const IndividualServices = ({homeData,loading}) => {
  if (loading) {
    return ;
  }
  return (
    <>

      <div className="bg-white p-8 lg:p-[60px] text-center lg:text-start xl:px-[120px] mt-10">
        <div className="block lg:grid grid-cols-2 ">
          <div className="w-full">
            <h1 className="text-3xl lg:text-5xl font-bold lg:max-w-xl text-[#262161]">
            {homeData.component4.heading}
            </h1>

            <div className="lg:flex mt-16">
              <div>
                <div className="bg-[#FFEDD8] p-4 lg:block inline-block rounded-xl">
                  <img src={homeData.component4.service1.icon} className="z-img-width" alt="" />
                </div>
              </div>
              <div >
                <h3 className="text-2xl font-bold">  {homeData.component4.service1.heading} </h3>
                {homeData.component4.service1.text}
              </div>
            </div>
            <div className="lg:flex mt-16">
              <div>
                <div className="bg-[#FFEDD8] p-4 lg:block inline-block rounded-xl">
                <img src={homeData.component4.service2.icon} alt="" className="z-img-width" />

                </div>
              </div>
              <div >
                <h3 className="text-2xl font-bold"> {homeData.component4.service2.heading}</h3>
                {homeData.component4.service2.text}
              </div>
            </div>
            <div className="lg:flex mt-16">
              <div>
                <div className="bg-[#FFEDD8] p-4 lg:block inline-block rounded-xl">
                <img src={homeData.component4.service3.icon ?? can} alt="" className="z-img-width" />

                </div>
              </div>
              <div >
                <h3 className="text-2xl font-bold"> {homeData.component4.service3.heading}</h3>
                {homeData.component4.service3.text}
              </div>
            </div>
            {/* <div className="lg:flex mt-16">
              <div>
                <div className="bg-[#FFEDD8] p-4 lg:block inline-block rounded-xl">
                  icon
                </div>
              </div>
              <div className="ml-6">
                <h3 className="text-2xl font-bold"> {homeData.component4.service4.heading}</h3>
                {homeData.component4.service4.text}
              </div>
            </div> */}
            <div className="text-center mt-16">
              <button
                type="button"
                className="bg-[#F69525] pl-10 pr-24 font-bold  w-64 py-2.5 relative  rounded-full"
              >
                Download App Now
                <div className="absolute p-5 right-1 bottom-1 rounded-full bg-[#262161]">
                  <img src={arrowWhite} alt="arrow" />
                </div>
              </button>
            </div>
          </div>

          <div className=" w-[85%] mx-0 mt-10">
            <div className="relative ml-16 sm:ml-16">
              {/* <img src={ homeData.component4.image_url ??phone2} className=" w-10/12   " alt="phone2" /> */}
              <img
                className="lg:absolute -top-6 xl:-top-16 w-11/12 xl:-right-40 -right-16 mt-0"
                src={homeData.component4.image_url2 ??phone1}
                alt="phone1"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="p-8 lg:p-16">
        <div className="py-8 lg:py-16 px-10 lg:px-20 rounded-xl bg-[#F69525]">
          <div className="text-center lg:text-start lg:flex  justify-between">
            <h1 className="text-white  font-bold text-3xl lg:text-5xl max-w-2xl ">
            {homeData.component5.download_app}
            </h1>
            <div className="flex  justify-between mt-4 lg:mt-0 ">
              <div className="lg:mr-20">
                <a target="_blank" href={homeData.component5.playstore_url??''}>
                <button  className="p-5 pr-4 pl-6 rounded-full bg-white">
                  <img src={homeData.component5.playstore_img_body??''} className="max-w-[3rem]" alt="arrow" />
                </button>
                </a>
              </div>
              <div>
                <a target="_blank" href={homeData.component5.appstore_url??''}>
                <button  className="p-5 rounded-full bg-white">
                  <img src={homeData.component5.appstore_img_body??''} className="max-w-[3rem]" alt="arrow" />
                </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-8 lg:p-16">
        <div className="lg:grid grid-cols-2 gap-16">
          <div>
            <div className="bg-white p-4 image-container shadow-xl">
              <img src={homeData.component6.image??workShop} className="h-50" alt="workShop" />
            </div>
          </div>
          <div className="text-center lg:text-start">
            <h1 className="lg:text-5xl text-2xl lg:mt-0 mt-10 max-w-lg mb-24 font-bold text-[#262161]">
              {/* Workshop & teaching services */}
              {homeData.component6.heading}
            </h1>
            <div className="flex mb-10">
              <img src={arrow} alt="arrow" className="text-black px-4" />
              <p className="text-lg">
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ulamco */}
                {homeData.component6.para1}
              </p>
            </div>
            <div className="flex mb-10">
              <img src={arrow} alt="arrow" className="text-black px-4" />
              <p className="text-lg">
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ulamco */}
                {homeData.component6.para2}
              </p>
            </div>
            <div className="flex mb-24">
              <img src={arrow} alt="arrow" className="text-black px-4" />
              <p className="text-lg">
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ulamco */}
                {homeData.component6.para3}
              </p>
            </div>
            <button
              type="button"
              className="bg-[#F69525]  font-bold  px-12 p-4 rounded-full"
              onClick={() => {
                const contactFormSection = document.getElementById('contactForm');
            
                if (contactFormSection) {
                  contactFormSection.scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndividualServices;
