import About from "./components/About";
import Testimonial from "./components/Testimonial";
import Services from "./components/Services";
import IndividualServices from "./components/IndividualServices";
import Contact from "./components/Contact";
import ServicesPartA from "./components/ServicesPartA";
import FAQSection from "./components/FAQSection";
import Stats from "./components/Stats";
import CompanyServices from "./components/CompanyServices";
import Partners from "./components/Partners";
import { useState, useEffect } from "react";
import { getHomePage } from "assets/firebase"; 
import { getMetaData } from "assets/firebase"; 
import { Helmet } from 'react-helmet-async';
import {Lottie, spinningArrowsAnimation} from "lottie-react";

const Home = () => {
  const [homeData, setHomeData] = useState(null); 
  const [metaData, setMetaData] = useState(null); 
  const [loading, setLoading] = useState(true); 
  

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {

        const data = await getHomePage();
        setHomeData(data.response);
        const meta = await getMetaData();
        setMetaData(meta.response);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []); // Empty dependency array to run this effect only once

  const [selectService, setSelectService] = useState("individual");
  if (loading) {
    return ''; /* (
      <div className="w-[200px] m-auto mt-5 mb-5">

        <div>
          <Lottie animationData={spinningArrowsAnimation} />
        </div>
      </div>
    ); */
  }
  return (
    <>
    <Helmet>
        <title>Home - Ebin</title>
        <meta name="description" content={metaData.description??''} />
        <meta name="keywords" content={metaData.keywords??''} />
        {/* Add more meta tags as needed */}
      </Helmet>
      <About
        homeData={homeData}
        loading={loading}

      />{/* component2 */}
      <Services
        selectService={selectService}
        setSelectService={setSelectService}
        homeData={homeData}
        loading={loading}
      />{/* component3 */}
      {/* individual services component4,5,6 */}
      {selectService === "individual" ? (
        <IndividualServices 
        homeData={homeData}
        loading={loading}
        
        />
      ) : (
        <>
          <ServicesPartA
          homeData={homeData}
          loading={loading} />
          <CompanyServices
          homeData={homeData}
          loading={loading} />
        </>
      )}
      <Stats
        homeData={homeData}
        loading={loading} />{/* component7 */}
      <Partners
        homeData={homeData}
        loading={loading} /> {/* component8 */}
      <Testimonial
        homeData={homeData}
        loading={loading} />{/* component9 */}
      <FAQSection 
      homeData={homeData}
      loading={loading} />{/* component10 */}
      <Contact
      homeData={homeData}
      loading={loading} />{/* component11 */}
    </>
  );
};

export default Home;
