import React, { useState } from "react";
import { company, connects, handShake, rotate, user } from "assets/main";

const Services = (props) => {

  const [selectedService, setSelectedService] = useState('individual');
  const { selectService, setSelectService,homeData,loading } = props;
  //const component3=JSON.stringify(props.homeData.component3);
  //onst data = JSON.parse(component3);
  //const aboutdata=JSON.stringify(props.homeData.component1);
 //console.log('aa '+(JSON.stringify(homeData.component3))  );
 if (loading) {
  return ;
}
  return (
    <>
      <div className="fourthCard-bg p-16" style={{ backgroundImage: homeData.component12.backgroundImage2 }}>
        <div className="lg:flex justify-center text-center items-center">
          <div>
            <h5 className="text-xl block text-[#F69525]">
             {homeData.component3.heading1}
                       </h5>
            <div className="mt-10">
              <h1 className="text-white font-bold text-5xl">
              {homeData.component3.heading2} {/*  Ready To Get Our Services? */}
              </h1>
            </div>
            <h1 className="text-gray-500 text-lg mt-4">
              {/* Switch the tab and select the option with you want to continue */}
              {homeData.component3.heading3}
            </h1>
            <div className="my-8 lg:flex justify-between text-red-500">
              <div className="p-4 lg:w-[20rem] mb-4 lg:mb-0 text-center card">
                <div className="rounded-full inline-block	 bg-slate-600 p-2">
                  <img src={homeData.component3.recycle_collection_icon} alt="rotate" className="h-[50px]" />
                </div>

                <div className="my-4">
                  <h2 className="text-lg text-white">
                   {homeData.component3.recycle_collection} 
                    </h2>
                    <p className="text-white">
                   {homeData.component3.recycle_collection_subh} 
                    </p>
                </div>
                <a href={homeData.component3.recycle_collection_url}>
                <button
                  type="button"
                  className="bg-[#F69525] px-10 py-3  rounded-full text-black font-medium"
                >
                  {homeData.component3.recycle_collection_btn}
                </button>
                </a>
              </div>
              <div className=" p-4 mb-4 lg:mb-0 lg:w-[20rem] text-center card ">
                <div className="rounded-full inline-block	 bg-slate-600 p-2">
                  <img src={homeData.component3.brand_partn_icon} alt="connects" className="h-[50px]" />
                </div>

                <div className="my-4">
                  <h2 className="text-lg text-white">
                    {homeData.component3.brand_partn}
                    </h2>
                    <p className="text-white">
                   {homeData.component3.brand_partn_subh} 
                    </p>
                </div>
                <a href={homeData.component3.brand_partn_url}>
                <button
                  type="button"
                  className="bg-[#F69525] px-10 py-3  rounded-full text-black font-medium"
                >
                  {homeData.component3.brand_partn_btn}
                </button></a>
              </div>
              <div className=" p-4 mb-4 lg:mb-0 lg:w-[20rem] text-center card">
                <div className="rounded-full inline-block	 bg-slate-600 p-2">
                  <img src={homeData.component3.sustainability_part_icon} alt="handShake" className="h-[50px] w-[50px]" />
                </div>
                <div className="my-4">
                  <h2 className="text-lg text-white">
                  
                   {homeData.component3.sustainability_part} 
                  </h2>
                  <p className="text-white">
                   {homeData.component3.sustainability_part_subh} 
                    </p>
                </div>
                <a href={homeData.component3.sustainability_part_url}>
                <button
                  type="button"
                  className="bg-[#F69525] px-10 py-3 rounded-full text-black font-medium"
                >
                  {homeData.component3.sustainability_part_btn}
                </button></a>
              </div>
            </div>

            <div className="text-center flex justify-center">
              <div className="flex justify-between rounded-full bg-white p-2">
                <button
                  type="button"
                  onClick={() => {
                    setSelectService('individual');
                    setSelectedService('individual');
                  }}
                  className={`relative pr-6 pl-16 py-3 rounded-full text-[#262161] font-medium ${selectedService === 'individual' ? 'bg-[#F69525]' : ''}`}
                >
                  <img
                    src={user}
                    alt="user"
                    className="absolute h-10 w-10 left-3 top-1 text-black"
                  />
                  Individual
                </button>
                <div className="border border-spacing-1 mx-2 border-black "></div>
                <button
                  type="button" onClick={() => {
                    setSelectService('company');
                    setSelectedService('company');
                  }}
                  className={`relative pr-6 pl-16 py-3 rounded-full text-black font-medium ${selectedService === 'company' ? 'bg-[#F69525]' : ''}`}
                >
                  <img
                    src={company}
                    alt="user"
                    className="absolute h-6 w-6 left-4 top-3 text-black"
                  />
                  Company
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
