import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LearnMoreRecycleable from "pages/learnMoreRecycleable/index";
import Home from "pages/home";
import Layout from "components/Layout";
import PageNotFound from "pages/home/components/PageNotFound";
import BecomePartner from "pages/becomePartner";
import PrivacyPolicy from "pages/privacy&policy";
import TermsAndConditons from "pages/terms&conditions";
import Shop from "pages/shop/shop";
import ThankYouPage from "pages/thankYou/thankyou";
import { RegionContext } from 'assets/RegionContext';

function App() {
  return (
    
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="shop" element={<Shop />} />
          <Route path="learn-more" element={<LearnMoreRecycleable />} />
          <Route path="become-partner" element={<BecomePartner />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="privacy-and-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-conditons" element={<TermsAndConditons />} />
          <Route path="thankYou" element={<ThankYouPage />} />
        </Route>
      </Routes>
    </Router>
    
  );
}

export default App;
