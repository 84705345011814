
import { term } from "assets/main";
import { getTermsPage } from "assets/firebase";
//import { bgPrivacy } from "assets/main";
import React, { useEffect, useState } from "react";
import Lottie from 'lottie-react';
import spinningArrowsAnimation from "assets/lottie/animation.json";
import he from 'he';
export default function TermsAndConditons() {
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {

        const data = await getTermsPage();

        setPageData(data.response); // Set the retrieved data to state
        setLoading(false);

      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);


  if (loading) {
    return (
      <div className="w-[200px] m-auto mt-5 mb-5">

        <div>
          <Lottie animationData={spinningArrowsAnimation} />
        </div>
      </div>
    );
  }
  const decodedContent = he.decode(pageData.terms);
  return (
    <section id="mainhead">
     {/*  <div className="lg:pb-16 lg:px-16 px-[20px] lg:relative">
        <img
          className=" rounded-[40px] lg:h-full h-[28rem] bg-black lg:w-full lg:ml-auto"
          src={term}
          alt="image1"
        />
        <div className="lg:-mt-10 mt-20 absolute top-[30%] text-center items-center lg:ml-36 lg:px-20 ">
          <div className="text-white mb-4 lg:mb-0 text-center text-2xl lg:text-[3rem] lg:leading-normal font-bold">
            <span dangerouslySetInnerHTML={{ __html: pageData.terms_heading }} />
          </div>
          <div className="text-md text-white text-center mb-8 mt-10">
            {pageData.terms_sub_heading}
          </div>
        </div>

      </div> */}
     <div className=" lg:!h-[27rem] !h-[40rem] rounded-[50px] lg:w-[84%] w-[100%] lg:ml-[9%]"
                style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)), url(${pageData.terms_background})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            >
            </div>

            <div className=" lg:!h-[27rem]  px-16 lg:mt-[-300px] mt-[-530px] text-white text-center lg:text-4xl text-3xl lg:text-[2.625rem] lg:leading-[3.1rem] font-bold">
               {/*  We Care About<br />Your
                <span className="text-[#F69525] lg:mr-4"> Privacy Policy</span> */}
                {/* {pageData.privacy_heading} */}
                <span dangerouslySetInnerHTML={{__html: pageData.terms_heading}} />
                <div className="text-[16px] text-[#fff] text-center items-center lg:mt-[50px] mt-[30px] lg:w-auto w-[300px] lg:ml-[40px] ml-[-10px] leading-[2rem] font-normal">
                {pageData.terms_sub_heading}
                </div>
            </div>
      <div className="md:flex px-16 my-10">
        <div className="max-w-4xl mx-auto p-8 p-2 bg-[#FFF] lg:mt-[-100px] mt-20">
          <div className="px-[1px] items-center lg:text-left text-center lg:text-[20px] text-[#282828] font-medium">
            {/* {pageData.privacy_policy} */}
            <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: decodedContent }} />
            {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</p>
                    <p className="mt-11">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud. exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>
                    <h2 className="font-bold mt-12 text-[22px]">Lorem ipsum dolor sit amet, consectetur adipiscing </h2>
                    <p className="mt-11">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim?</p>
                    <p className="mt-11">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud. exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>
                    <p className="mt-11">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</p>
                    <h2 className="font-bold mt-12 text-[22px]">Lorem ipsum dolor sit amet, consectetur adipiscing </h2>
                    <p className="mt-11">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim?</p>
                    <p className="mt-11">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud. exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>
                    <p className="mt-11">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</p>
                */}
          </div>

        </div>
        {/* <div className="lg:block hidden">
          <ul className="flex-column space-y min-h-[700px] space-y-4 text-sm bg-zinc-100 font-medium text-gray-500 md:me-4 mb-4 md:mb-0">
            {[
              "Terms of use",
              "Intelectual property agreement",
              "Prohibitted activities",
              "Terminaton clause",
              "Governing law",
            ].map((text) => (
              <li>
                <button className="inline-block whitespace-nowrap text-right text-zinc-800 text-xl font-bold 	 items-center px-4 py-3 border-r-4 border-orange-500 bor rounded-l-lg active w-full ">
                  {text}
                </button>
              </li>
            ))}
          </ul>
        </div> */}
        {/*  <div className="lg:block xl-block hidden p-6 bg-white text-medium text-gray-500  rounded-lg w-full">
        <div dangerouslySetInnerHTML={{ __html: pageData.terms }} />
        </div> */}
        {/* <div className="lg:hidden block text-center ">
          <div className="p-6 bg-white text-medium text-gray-500  rounded-lg w-full">
            <h3 className="text-lg font-bold text-gray-900  mb-2">
              Terms of use
            </h3>
            <p className="mb-2 text-zinc-800 text-[14px] font-normal">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            </p>
            <p className="mb-2 text-zinc-800 text-[14px] font-normal">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            </p>
        
          </div>
          <div className="p-6 bg-white text-medium text-gray-500  rounded-lg w-full">
            <h3 className="text-lg font-bold text-gray-900  mb-2">
              Intelectual property agreement
            </h3>
          
            <p className="mb-2 text-zinc-800 text-[14px] font-normal">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            </p>
            <p className="mb-2 text-zinc-800 text-[14px] font-normal">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            </p>
          </div>
          <div className="p-6 bg-white text-medium text-gray-500  rounded-lg w-full">
            <h3 className="text-lg font-bold text-gray-900  mb-2">
              Prohibitted activities
            </h3>
            <p className="mb-2 text-zinc-800 text-[14px] font-normal">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            </p>
          </div>
          <div className="p-6 bg-white text-medium text-gray-500  rounded-lg w-full">
            <h3 className="text-lg font-bold text-gray-900  mb-2">
              Terminaton clause
            </h3>
            <p className="mb-2 text-zinc-800 text-[14 px] font-normal">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            </p>
          </div>
        </div> */}
      </div>
    </section>
  );
}
