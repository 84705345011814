// Import the functions you need from the SDKs you need
import {
  initializeApp
} from "firebase/app";
import {
  getAnalytics
} from "firebase/analytics";
import {
  doc,
  getDoc,
  getFirestore,
  increment,
  updateDoc
} from "firebase/firestore";
import {
  collection,
  getDocs
} from "firebase/firestore";
import {
  addDoc
} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


const firebaseConfig = {
  apiKey: "AIzaSyBOVqCAJI4BgNGSP0KM9mq2mEJJIAzF3PQ",
  authDomain: "ebin-b50a2.firebaseapp.com",
  databaseURL: "https://ebin-b50a2-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ebin-b50a2",
  storageBucket: "ebin-b50a2.appspot.com",
  messagingSenderId: "845302139160",
  appId: "1:845302139160:web:38cf592ba72e2066e7f66d",
  measurementId: "G-WQNY5E3QG6"
};
// Initialize Firebase

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
let region = localStorage.getItem("region")
//console.log(db);
/* const analytics = getAnalytics(app);
const db = getFirestore(app); */
// Function to test Firestore connection
const testFirestoreConnection = async () => {
  try {

    const querySnapshot = await getDocs(collection(db, 'App_Users')); // Replace 'your_collection' with your Firestore collection name
    querySnapshot.forEach((doc) => {
      console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
    });



  } catch (error) {
    console.error('Error fetching data: ', error);
  }
};
const getHomePage = async () => {
  try {
    /*  const querySnapshot = await getDocs(collection(db, 'Web_Content')); 
     let responseData = querySnapshot.data;
     querySnapshot.forEach((doc) => {
       if (doc.id === 'home_page') {
        // console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
         //responseData = JSON.stringify(doc.data());
         responseData = doc.data();
         
       }
        
     });
     console.log('success');
     return { response: responseData }; */
 

     let col_name='Web_Content';
     if (region==='sa') {
       col_name = 'KSA_Web_Content'; 
      }
      const docRef = doc(db, col_name, 'home_page');
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const responseData = docSnapshot.data();
      console.log('success' + responseData);
      return {
        response: responseData
      };
    } else {
      console.log('Document not found');
      return {
        response: null
      }; // or handle the absence of the document as needed
    }

  } catch (error) {
    console.error('Error fetching data: ', error);
  }
};
const getTermsPage = async () => {
  try {
    
    //console.log('test');
    let col_name='Web_Content';
     if (region==='sa') {
       col_name = 'KSA_Web_Content'; 
      }
    const docRef = doc(db, col_name, 'terms_and_conditions');
    
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const responseData = docSnapshot.data();
      console.log('success' + responseData);
      return {
        response: responseData
      };
    } else {
      console.log('Document not found');
      return {
        response: null
      }; // or handle the absence of the document as needed
    }

  } catch (error) {
    console.error('Error fetching data: ', error);
  }
};
const getSocialMedia = async () => {
  try {
    
    let col_name='Web_Content';
     if (region==='sa') {
       col_name = 'KSA_Web_Content'; 
      }
    const docRef = doc(db, col_name, 'social_media');
    
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const responseData = docSnapshot.data();
      console.log('success' + responseData);
      return {
        response: responseData
      };
    } else {
      console.log('Document not found');
      return {
        response: null
      }; 
    }

  } catch (error) {
    console.error('Error fetching data: ', error);
  }
};
const getNavbar = async () => {
  try {
    
    let col_name='Web_Content';
    if (region==='sa') {
      col_name = 'KSA_Web_Content'; 
     }
    const docRef = doc(db, col_name, 'navbar');
   
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const responseData = docSnapshot.data();
      console.log('success' + responseData);
      return {
        response: responseData
      };
    } else {
      console.log('Document not found');
      return {
        response: null
      }; 
    }

  } catch (error) {
    console.error('Error fetching data: ', error);
  }
};
const getMetaData = async () => {
  try {
    
    let col_name='Web_Content';
     if (region==='sa') {
       col_name = 'KSA_Web_Content'; 
      }
    const docRef = doc(db, col_name, 'meta_tags');
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const responseData = docSnapshot.data();
      console.log('success' + responseData);
      return {
        response: responseData
      };
    } else {
      console.log('Document not found');
      return {
        response: null
      }; 
    }

  } catch (error) {
    console.error('Error fetching data: ', error);
  }
};
const getLearnMorePage = async () => {
  try {
    let lar_col_name='Learn_About_Recyclables';
    let lar_videos_col_name='Learn_more_videos';
    let lar_type_col_name='LAR_Types';
    let lar_cat_col_name='LAR_Categories';
     if (region==='sa') {
       lar_col_name = 'KSA_Learn_About_Recyclables'; 
       lar_videos_col_name='KSA_Learn_more_videos';
       lar_type_col_name='KSA_LAR_Types';
       lar_cat_col_name='KSA_LAR_Categories';

      }
    const querySnapshot = await getDocs(collection(db, lar_col_name));
    const responseData = [];
    querySnapshot.forEach((doc) => {
      responseData.push(doc.data());
    });
    const querySnapshot1 = await getDocs(collection(db, lar_videos_col_name));
    const videoData = [];
    querySnapshot1.forEach((doc) => {
      videoData.push(doc.data());
    });
    const querySnapshot2 = await getDocs(collection(db, lar_type_col_name));
    const types = [];
    querySnapshot2.forEach((doc) => {
      types.push(doc.data());
    });
    const querySnapshot3 = await getDocs(collection(db, lar_cat_col_name));
    const categories = [];
    querySnapshot3.forEach((doc) => {
      categories.push(doc.data());
    });

/*     const docRef = doc(db, 'Web_Content', 'learn_more');
    const docSnapshot = await getDoc(docRef);
    let contentData = null;

    if (docSnapshot.exists()) {
      contentData = docSnapshot.data();
      console.log('success', types);
    } else {
      console.log('Document not found');
    } */

    return {
      response: responseData,
     // content: contentData,
      videos: videoData,
      types:types,
      categories:categories
    };
  } catch (error) {
    console.error('Error fetching data: ', error);
    return { response: [], content: null,videos: [] };
  }
};
const getBecomePartnerPage = async () => {
  try {
    let col_name='Become_Partner_Questions';
     if (region==='sa') {
       col_name = 'KSA_Become_Partner_Questions'; 
      }
    const querySnapshot = await getDocs(collection(db, col_name));
    const responseData = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      responseData.push(data);
    });
 

    return  responseData;
  } catch (error) {
    console.error('Error fetching data: ', error);
    return { response: [], content: null,videos: [] };
  }
};

const saveContactUs = async (formData) => {
  try {
    let col_name='Web_Contact_Form';
    let not_col_name='Admin_Notifications';
    if (region==='sa') {
      col_name = 'KSA_Web_Contact_Form'; 
      not_col_name = 'KSA_Admin_Notifications'; 
     }
    const docRef = await addDoc(collection(db, col_name), formData);
    const updateRef = doc(db, not_col_name, 'contact_form'); 
    const docSnap = await getDoc(updateRef);
  if (docSnap.exists()) {
    const notifyValue = docSnap.data().notify;
    await updateDoc(updateRef, {notify:notifyValue+1});
  }
    
    console.log('Document written with ID: ', docRef.id);
    return {
      success: true,
      docId: docRef.id
    };
  } catch (error) {
    console.error('Error adding document: ', error);
    return {
      success: false,
      error
    };
  }
};
const savePartner = async (formData) => {
  try {
    let col_name='Web_Partner_Form';
    let not_col_name='Admin_Notifications';
    if (region==='sa') {
      col_name = 'KSA_Web_Partner_Form'; 
      not_col_name = 'KSA_Admin_Notifications'; 
     }
    const docRef = await addDoc(collection(db, col_name), formData);
    const updateRef = doc(db, not_col_name, 'partner_form'); 
    const docSnap = await getDoc(updateRef);
  if (docSnap.exists()) {
    const notifyValue = docSnap.data().notify;
    await updateDoc(updateRef, {notify:notifyValue+1});
  }
    console.log('Document written with ID: ', docRef.id);
    return {
      success: true,
      docId: docRef.id
    };
  } catch (error) {
    console.error('Error adding document: ', error);
    return {
      success: false,
      error
    };
  }
};
//getHomePage()
export {
  saveContactUs,
  savePartner,
  getHomePage,
  getLearnMorePage,
  getTermsPage,
  getBecomePartnerPage,
  getSocialMedia,
  getNavbar,
  getMetaData
};
// Call the function to test the connection

//export  {db};