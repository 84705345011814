import React, { useState, useRef } from "react";
import { group } from "assets/main";
import {user} from "assets/main";
const Testimonial = ({ homeData, loading }) => {

  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = 200; // Adjust the scroll amount as needed
      container.scrollLeft -= scrollAmount;
      setScrollPosition(container.scrollLeft);
    }
  };

  const scrollRight = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = 200; // Adjust the scroll amount as needed
      container.scrollLeft += scrollAmount;
      setScrollPosition(container.scrollLeft);
    }
  };
  if (loading) {
    return;
  }
  //console.log('testimonial'+JSON.stringify(homeData.component9));
  const reviewsData = homeData.component9;
  const reviews = Object.keys(reviewsData)
    .filter(key => key.includes('review'))
    .map(key => reviewsData[key]);
  console.log('reviews' + JSON.stringify(reviews));
  return (
    <>
      <div className="lg:p-16 bg-zinc-100">
        <div className="flex flex-col lg:flex-row justify-between items-center px-8">
          <div className="lg:w-[400px] lg:mr-8 mb-6 lg:mb-0 mt-6 sm:mt-0">
            <div className="lg:text-3xl text-[#262161] font-extrabold">
              {homeData.component9.heading}
            </div>
          </div>
          <div className="flex items-center">
            <button
              type="button"
              className="p-2 rounded-full bg-[#262161] hidden sm:block"
              onClick={scrollLeft}
            >
              <svg
                className="w-8 h-8 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 5H1m0 0 4 4M1 5l4-4"
                />
              </svg>
            </button>
            <button
              type="button"
              className="ml-4 p-2 bg-[#F69525] rounded-full hidden sm:block"
              onClick={scrollRight}
            >
              <svg
                className="w-8 h-8 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="white"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="pt-8 pb-12">
          {/*  <div
            className="flex scroll-bar-hidden direction-row gap-8 overflow-x-auto"
            ref={scrollContainerRef}
          >
            {reviews
              .fill("a")
              .map((value, index) => (
                <div
                  key={index + value}
                  className="  min-w-[22rem] max-w-sm"
                >
                    
                  <div className="relative">
                  <img src={group} alt="group" />
                  <div className="absolute top-4 mb-8 p-4 px-10 my-4">
                  <h5 className="mb-4 text-xl font-bold tracking-tight text-gray-900">
                  {homeData.component9.review1.heading}{" "}
                  </h5>
                  <p className="font-normal text-gray-700">
                  {homeData.component9.review1.text}
                  </p>
                  <h1 className="mt-6 font-bold text-gray-900">
                  {homeData.component9.review1.name}
                  </h1>
                  <span className="text-gray-700 mb-4">{homeData.component9.review1.company}</span>

                  </div>
                  </div>

                  
                </div>
              ))}
          </div> */}


          <div className="flex scroll-bar-hidden direction-row gap-8 overflow-x-auto pb-10 ml-3 sm:ml-0" ref={scrollContainerRef}>
            {reviews.map((review, index) => (
              <div key={index} className="min-w-[22rem] max-w-sm  ">
                <div className="container mx-auto p-8 bg-white rounded-[40px] shadow-xl relative min-h-[350px] ">
             
                  <h5 className="mb-4 text-xl font-bold tracking-tight text-gray-900 h-[40px]">
                      {review.heading}
                    </h5>
                    <p className="font-normal text-gray-700 text-justify h-[140px]">
                      {review.text}
                    </p>
                    <h1 className="mt-10 font-bold text-gray-900">
                      {review.name}
                    </h1>
                    <span className="text-gray-700 mb-4">
                      {review.company}
                    </span>
                  <img src={review.image ?? user}  className="absolute bottom-0 right-0 h-16 object-cover w-16 rounded-lg mb-4 border-2 border-[#F69525] mr-4 " />
                </div>
                {/* <div className="relative">
                  <img src={group} alt="group" />
                  <div className="absolute top-4 mb-8 p-4 px-10 my-4">
                    <h5 className="mb-4 text-xl font-bold tracking-tight text-gray-900">
                      {review.heading}
                    </h5>
                    <p className="font-normal text-gray-700">
                      {review.text}
                    </p>
                    <h1 className="mt-6 font-bold text-gray-900">
                      {review.name}
                    </h1>
                    <span className="text-gray-700 mb-4">
                      {review.company}
                    </span>
                  </div>
                </div> */}
              </div>
            ))}
          </div>



        </div>
      </div>
    </>
  );
};

export default Testimonial;
