import { getHomePage, getSocialMedia } from "assets/firebase";
import { facebook, instagram, logo, twitter, youtube, WhatsAppIcon, logoFooter } from "assets/main";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ZohoChat from './zoho';
const Footer = () => {
  const [socialData, setSocialData] = useState(null);
  const [footorData, setFooterData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Fetch data when the component mounts

    const fetchData = async () => {
      try {

        const data = await getHomePage();
        setFooterData(data.response);
        const social = await getSocialMedia();
        setSocialData(social.response);
        setLoading(false);

      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  if (loading) {
    return 0;
  }
  return (
    <>

      <footer className="bg-[#252160] bottom-0 p-8 lg:px-24 py-8">


        {/* <a
          href="https://wa.me/+97335390649"
          className="fixed bottom-4 right-4 lg:bottom-8 lg:right-8 bg-white hover:bg-green-700 text-white p-4 rounded-full"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={WhatsAppIcon} alt="WhatsApp" className="h-8 w-8" />
        </a>
 */}

        <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
          <div className="mb-6 lg:hidden flex justify-center">
            <img src={logoFooter??''} alt="logo" />
          </div>
          <div className=" lg:grid-cols-4 grid  gap-4">


            <div className="mb-6 hidden lg:block lg:mb-0">
              <img src={logoFooter??''} alt="logo" />
            </div>
            <div className="flex flex-col items-center justify-center sm:items-start">
              <div className="image-container-ft" >
                <a href={footorData.component5.playstore_url??''}>
                  <img className="mb-3 image-ft " src={footorData.component5.playstore_img_footer??''} alt="logo" />
                </a>
                </div>
              <div className="image-container-ft" >
                <a href={footorData.component5.appstore_url??''}>
                  <img src={footorData.component5.appstore_img_footer??''} alt="logo" />
                </a>
              </div>
              {/*  <h2 className="mb-6 text-sm font-semibold text-white">
                Services
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <Link
                    to={"/become-partner"}
                    className="hover:underline">Become a partner</Link>
                </li>
              </ul> */}

            </div>
            <div className="flex flex-col items-center justify-center sm:items-start">
              <h2 className="mb-6 text-sm font-semibold text-white dark:text-white">
                Portal
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  
                  <a
                    href="https://ebincompany.com/portal/"
                    rel="noopener noreferrer"
                    className="hover:underline"
                  >
                    <h2 className=" text-sm font-semibold text-white dark:text-white">Go to our portal</h2>
                  </a>
                  
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-center justify-center sm:items-start">
              <h2 className="mb-6 text-sm font-semibold text-white dark:text-white">
                Quick Links
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <Link
                    to={"/privacy-and-policy"}
                    className="hover:underline "
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    to={"/terms-and-conditons"}
                    className="hover:underline "
                  >
                    Terms & Conditions
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 mx-auto lg:my-8" />
        <div className="flex flex-col-reverse lg:flex-row  items-center lg:justify-between ">
          <div className="text-sm text-gray-500">
          All rights reserved to The eBin Company WLL. © 2019
          </div>
          <div className="text-gray-500  mb-4 lg:mb-0">
            {footorData.component1.copyright_text}
          </div>
          <div className="lg:flex mt-4 mb-4 lg:mb-0 lg:justify-center sm:mt-0">

            {socialData.instagram.status === 'true' &&
              <a href={socialData.instagram.url} target="_blank" rel="noopener noreferrer">
                <button
                  type="button"
                  className="hover:bg-[#F69525]  p-2 mr-4 bg-white  rounded-full"
                >
                  <img
                    src={socialData.instagram.icon}
                    className="h-8 w-8 text-white"
                    alt="instagram"
                  />
                </button>
              </a>
            }
            {socialData.tiktok.status === 'true' &&
              <a href={socialData.tiktok.url} target="_blank" rel="noopener noreferrer">
                <button
                  type="button"
                  className="hover:bg-[#F69525]  p-2 mr-4 bg-white  rounded-full"
                >
                  <img
                    src={socialData.tiktok.icon}
                    className="h-8 w-8 text-white"
                    alt="tiktok"
                  />
                </button>
              </a>
            }
            {socialData.linkden.status === 'true' &&
              <a href={socialData.linkden.url} target="_blank" rel="noopener noreferrer">
                <button
                  type="button"
                  className="hover:bg-[#F69525]  p-2 mr-4 bg-white  rounded-full"
                >
                  <img
                    src={socialData.linkden.icon}
                    className="h-8 w-8 text-white"
                    alt="linkden"
                  />
                </button>
              </a>
            }
            {socialData.youtube.status === 'true' &&
              <a href={socialData.youtube.url} target="_blank" rel="noopener noreferrer">
                <button
                  type="button"
                  className="hover:bg-[#F69525]  p-2 mr-4 bg-white  rounded-full"
                >
                  <img
                    src={socialData.youtube.icon}
                    className="h-8 w-8 text-white"
                    alt="youtube"
                  />
                </button>
              </a>
            }
            {socialData.facebook.status === 'true' &&
              <a href={socialData.facebook.url} target="_blank" rel="noopener noreferrer">
                <button
                  type="button"
                  className="hover:bg-[#F69525]  p-2 mr-4 bg-white  rounded-full"
                >
                  <img
                    src={socialData.facebook.icon}
                    className="h-8 w-8 text-white"
                    alt="facebook"
                  />
                </button>
              </a>
            }
            {socialData.twitter.status === 'true' &&
              <a href={socialData.twitter.url} target="_blank" rel="noopener noreferrer">
                <button
                  type="button"
                  className="hover:bg-[#F69525]  p-2 mr-4 bg-white  rounded-full"
                >
                  <img
                    src={socialData.twitter.icon}
                    className="h-8 w-8 text-white"
                    alt="twitter"
                  />
                </button>
              </a>
            }

          </div>
        </div>
        <ZohoChat />
      </footer>
    </>
  );
}

export default Footer;
