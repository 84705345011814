import { getLearnMorePage } from "assets/firebase";
import {
  botle,
  box,
  can,
  check,
  cross,
  downarrow,
  /* image10,
  image11,
  image12,
  image13,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  
  mask2,
  mask3,
  other,
  
  sampleVideo,
  videoImage1,
  videoImage2,
  videoImage3,
  videoImage4,
  videoImage5,
  videoImage6, */
  mask, play,
  videoPreview,
  videoSecond,
} from "assets/main";

import React, { useEffect, useState } from "react";
import Lottie from 'lottie-react';
import spinningArrowsAnimation from "assets/lottie/animation.json";

const LearMoreRecycleable = () => {
  const [pageData, setPageData] = useState(null);
  const [contentData, setContentData] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [types, setTypes] = useState(null);
  const [categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [openAccordion, setOpenAccordion] = useState(null);
  const [openAccordion2, setOpenAccordion2] = useState(null);

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {

        const data = await getLearnMorePage();

        setPageData(data.response); // Set the retrieved data to state
        setContentData(data.content); // Set the retrieved data to state
        setVideoData(data.videos);
        setTypes(data.types);
        setCategories(data.categories);
        setLoading(false);

      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);


  if (loading) {
    return (
      <div className="w-[200px] m-auto mt-5 mb-5">

        <div>
          <Lottie animationData={spinningArrowsAnimation} />
        </div>
      </div>
    );
  }
  console.log('types' + JSON.stringify(types));
  types.sort((a, b) => a.order - b.order);
  categories.sort((a, b) => a.order - b.order);
  //const typeList = categories.filter(cat=> types);
  var typeList = [];
  types.forEach((type) => {
    if (categories.some(e => e.lar_type != type.title)) {
      typeList.push(type);
    }
  });
  var categoriesList = [];
  categories.forEach((cat) => {
    if (pageData.some(e => e.category == cat.title)) {
      categoriesList.push(cat);
    }
  });
  const handleAccordionToggle = (type) => {
    setOpenAccordion(openAccordion === type ? '' : type);
  };
  const handleAccordionToggle2 = (type) => {
    setOpenAccordion2(openAccordion2 === type ? '' : type);
    console.log('ddddd');
  };
  console.log('zeedata' + JSON.stringify(categoriesList));
  return (
    <>
      {/*  <div className="grid lg:grid-cols-12 lg:px-16">
        <div className="text-center">
          <img className="bg-gray-500 rounded-[60px] p-10 mb-1" src={can} alt="" />
          <h6>text here</h6>
        </div>

      </div> */}

      <div className="m-4 lg:mx-32 mb-10">
        {types.map((type) => (
          <div>
            <div onClick={() => handleAccordionToggle(type.title)}>
              <div className="flex justify-between flex-wrap p-2 items-center shadow-2xl border-2 rounded-2xl mt-16">
                <div className="flex items-center">
                  <div className={`p-3 inline-block rounded-full`} style={{ backgroundColor: type.color }}>
                    <img className="w-[30px]" src={type.image} alt="can" />
                  </div>
                  <div className="ml-2 lg:ml-6">
                    <h3 className="text-lg lg:text-2xl font-bold">
                      {type.title}
                    </h3>
                  </div>
                </div>
                <div className=" p-2 mr-2 bg-[#D9D9D9] inline-block rounded-full">

                  <img className="h-2 w-3" src={downarrow} alt="downarrow" />
                </div>

              </div>
            </div>


            {openAccordion === type.title && categoriesList.filter(category => category.lar_type == type.title).map((cat) => (

              <div>


                <div className="" >
                  <div className="mx-auto ">

                    <div className="mt-8 mb-8">
                      <button
                        type="button"
                        className="px-16 w-full py-3.5 text-base font-bold flex justify-center items-center bg-[#F4F4F4] rounded-lg text-center text-[#262163]"
                        onClick={() => handleAccordionToggle2(cat.title)}
                      >
                        {/* <img className="mr-2 h-8 w-8" src={mask} alt="mask" /> */}
                        {cat.title}
                      </button>
                      {openAccordion2===cat.title &&

                      <div>

                <h3 className="lg:text-2xl text-lg text-[#4BAE4F]  font-bold ml-5 mt-4">
                        Allowed
                      </h3>
                      <div className="mt-2 grid lg:grid-cols-6 grid-cols-2 gap-16 p-4">
                        {pageData.filter(product => product.type == cat.lar_type && product.allowed == 'true' && cat.title == product.category).map((filterp) => (
                          <div>

                            <div className="text-center" style={{ width: 'fit-content', }}>
                              <img className="bg-gray-500 rounded-[50%]  mb-1 w-full h-auto object-cover" src={filterp.image} alt="" />
                              <p>{filterp.text}</p>
                            </div>
                          </div>

                        ))}

                      </div>
                      <h3 className="text-2xl text-[#F44336] font-bold ml-5">Not Allowed</h3>
                      <div className="mt-2 grid lg:grid-cols-6 grid-cols-2 gap-16 p-4">

                        {pageData.filter(product => product.type == cat.lar_type && product.allowed == 'false' && cat.title == product.category).map((filterp) => (
                          <div>

                            <div className="text-center" style={{ width: 'fit-content', }}>
                              <img className="bg-gray-500 rounded-[50%]  mb-1 w-full h-auto object-cover" src={filterp.image} alt="" />
                              <p>{filterp.text}</p>
                            </div>
                          </div>

                        ))}

                      </div>
                      </div>
                      }
                    </div>
                  </div>

                </div>
              </div>
            ))}
            {openAccordion === type.title && !(categoriesList.some(function (cat) {return cat.lar_type == type.title;})) && typeList.filter(tl => tl.title == type.title).map((cat) => (
              <div>


                <div className="row mt-4 p-4" >
                  <div className="mx-auto  ">
                    <h3 className="lg:text-2xl text-lg text-[#4BAE4F]  font-bold ml-5">
                      Allowed
                    </h3>
                    <div className="mt-2 grid lg:grid-cols-6 grid-cols-2 gap-16 p-4">

                      {pageData.filter(product => product.type == cat.title && product.allowed == 'true').map((filterp) => (
                        <div >
                          <div className="text-center" style={{ width: 'fit-content' }}>
                            <img className="bg-gray-500 rounded-[50%]  mb-1 w-full h-auto object-cover" src={filterp.image} alt="dd" />
                            <p>{filterp.text}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <h3 className="text-2xl text-[#F44336] font-bold ml-5">Not Allowed</h3>
                    <div className="mt-2 grid lg:grid-cols-6 grid-cols-2 gap-16 p-4">

                      {pageData.filter(product => product.type == cat.title && product.allowed == 'false').map((filterp) => (
                        <div >
                          <div className="text-center" style={{ width: 'fit-content' }}>
                            <img className="bg-gray-500 rounded-[50%]  mb-1 w-full h-auto object-cover" src={filterp.image} alt="dd" />
                            <p>{filterp.text}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                </div>
              </div>
            ))}
          </div>
        ))}
      </div >
      {/*  <div className="m-4 lg:mx-32 mb-10">
        {types.map((type) => (
          <div>
            <div onClick={() => handleAccordionToggle(type.title)}>
              <div className="flex justify-between flex-wrap p-2 items-center shadow-2xl border-2 rounded-2xl mt-16">
                <div className="flex items-center">
               <div className={`p-3 inline-block rounded-full`} style={{ backgroundColor: type.color }}>
                    <img className="w-[30px]" src={type.image} alt="can" />
                  </div>
                  <div className="ml-2 lg:ml-6">
                    <h3 className="text-lg lg:text-2xl font-bold">
                      {type.title}
                    </h3>
                  </div>
                </div>
                <div className=" p-2 mr-2 bg-[#D9D9D9] inline-block rounded-full">

                  <img className="h-2 w-3" src={downarrow} alt="downarrow" />
                </div>

              </div>
            </div>


            {openAccordion === type.title && categoriesList.filter(category => category.lar_type == type.title ).map((cat) => (
            
              <div>
               

                <div className="grid grid-cols-12 mt-10" >
                  <div className="mx-auto  lg:col-span-5 col-span-12">
                    <h3 className="lg:text-2xl text-lg text-[#4BAE4F]  font-bold">
                      Allowed
                    </h3>
                    <div className="mt-4">
                      <button
                        type="button"
                        className="px-16 w-full py-3.5 text-base font-bold flex justify-center items-center bg-[#F4F4F4] rounded-lg text-center text-[#262163]"
                      >
                        <img className="mr-2 h-8 w-8" src={mask} alt="mask" />
                        {cat.title}
                      </button>
                      {pageData.filter(product => product.type == cat.lar_type && product.allowed == 'true' && cat.title == product.category).map((filterp) => (
                        <div>
                          <ul className="mt-8 pl-8">
                            <li className="list-disc">
                              {filterp.text}
                            </li>
                          </ul>
                          <img
                            className="mt-8 lg:ml-16 lg:h-[200px]"
                            src={filterp.image}
                            alt="image3"
                          />
                          <div className="flex justify-end  ">
                            <img className="mt-8 ml-16" src={check} alt="check" />
                            <h3 className=" mt-8 ml-2 pr-14 text-[#4BAE4F]">Allowed</h3>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-span-2 hidden justify-center py-32 lg:flex">
                    <div className="border  border-spacing-1  mx-8 border-gray-500 "></div>
                  </div>
                  <div className="lg:col-span-5 col-span-12 mx-auto">
                    <h3 className="text-2xl text-[#F44336] font-bold">Not Allowed</h3>
                    <div className=" mt-4">
                    <button
                        type="button"
                        className="px-16 w-full py-3.5 text-base font-bold flex justify-center items-center bg-[#F4F4F4] rounded-lg text-center text-[#262163]"
                      >
                        <img className="mr-2 h-8 w-8" src={mask} alt="mask" />
                        {cat.title}
                      </button>
                      {pageData.filter(product => product.type == cat.lar_type && product.allowed == 'false' && cat.title == product.category).map((filterp) => (
                        <div>
                          <ul className="mt-8 pl-8">
                            <li className="list-disc">{filterp.text}</li>
                          </ul>
                          <img
                            className="mt-8 lg:ml-16 lg:h-[200px]"
                            src={filterp.image}
                            alt="image4"
                          />
                          <div className="flex justify-end pl-16">
                            <img className="mt-8 ml-14" src={cross} alt="cross" />
                            <h3 className="mt-8 ml-2 text-[#F44336]">Not Allowed</h3>
                          </div>
                        </div>
                      ))}

                    
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {openAccordion === type.title && typeList.filter(tl => tl.title == type.title ).map((cat) => (
              <div>
                

                <div className="grid grid-cols-12 mt-10" >
                  <div className="mx-auto  lg:col-span-5 col-span-12">
                    <h3 className="lg:text-2xl text-lg text-[#4BAE4F]  font-bold">
                      Allowed
                    </h3>
                    <div className="mt-4">
                    
                      {pageData.filter(product => product.type == cat.title && product.allowed == 'true').map((filterp) => (
                        <div>
                          <ul className="mt-8 pl-8">
                            <li className="list-disc">
                              {filterp.text}
                            </li>
                          </ul>
                          <img
                            className="mt-8 lg:ml-16 lg:h-[200px]"
                            src={filterp.image}
                            alt="image3"
                          />
                          <div className="flex justify-end  ">
                            <img className="mt-8 ml-16" src={check} alt="check" />
                            <h3 className=" mt-8 ml-2 pr-14 text-[#4BAE4F]">Allowed</h3>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-span-2 hidden justify-center py-32 lg:flex">
                    <div className="border  border-spacing-1  mx-8 border-gray-500 "></div>
                  </div>
                  <div className="lg:col-span-5 col-span-12 mx-auto">
                    <h3 className="text-2xl text-[#F44336] font-bold">Not Allowed</h3>
                    <div className=" mt-4">
                 
                      {pageData.filter(product => product.type == cat.title && product.allowed == 'false').map((filterp) => (
                        <div>
                          <ul className="mt-8 pl-8">
                            <li className="list-disc">{filterp.text}</li>
                          </ul>
                          <img
                            className="mt-8 lg:ml-16 lg:h-[200px]"
                            src={filterp.image}
                            alt="image4"
                          />
                          <div className="flex justify-end pl-16">
                            <img className="mt-8 ml-14" src={cross} alt="cross" />
                            <h3 className="mt-8 ml-2 text-[#F44336]">Not Allowed</h3>
                          </div>
                        </div>
                      ))}

                      
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div> */}

      <div div className="grid bg-white p-8 lg:px-16 py-8 lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12" >
        <div className="mr-auto  lg:col-span-7">
          <h1 className="max-w-2xl mb-10 text-violet-950 lg:text-5xl text-center lg:text-start  text-[25px] font-extrabold  tracking-tight leading-none md:text-5xl xl:text-6xl ">
            High Quality Videos For You To Learn Quickly
          </h1>
          <div className="flex lg:hidden lg:mt-0 my-4 bg-violet-950 rounded-[50px] relative shadow p-4 lg:col-span-5">
            <img src={videoPreview} alt="videoPreview" />
            <img
              className="absolute -left-6 sm:-left-10  w-28  sm:w-32 md:w-40 top-16 sm:top-24"
              src={videoSecond}
              alt="videoSecond"
            />
          </div>
          <p className="max-w-2xl mb-10 text-[15px] font-normal  lg:font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl ">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliqui. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            do eiusmod tempor incididunt.
          </p>
          <button className="inline-flex items-center  text-center text-zinc-800 text-[22px] font-bold bg-orange-400 rounded-[100px] justify-center px-7 py-3 mr-3 text-base   hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
            watch videos
          </button>
        </div>
        <div className="hidden lg:mt-0 mt-4 bg-violet-950 rounded-[50px] relative shadow p-4 lg:col-span-5 lg:flex">
          <img src={videoPreview} alt="videoPreview" />
          <img
            className="absolute -left-10 sm:-left-16 lg:-left-20 w-28  sm:w-32 md:w-40 top-16 sm:top-24"
            src={videoSecond}
            alt="videoSecond"
          />
        </div>
      </div >
      <div className="space-y-8 px-8 lg:px-16 mb-32 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">

        {videoData.map((video, index) => (
          <div key={index} className="bg-zinc-100 border border-gray-200 rounded-lg shadow mb-4 w-96 h-500px" >
            <img className="rounded-t-lg p-4 pb-0 object-cover " src={video.image} alt="" />
            <div className="p-5">
              <h5 className="mb-2 text-xl text-neutral-700 text-[25px] font-bold tracking-tight">
                {video.heading}
              </h5>
              <div className="flex justify-between">
                <div className="w-2/3">
                  <p className="mb-3 text-[15px] text-neutral-400 lg:text-xl font-medium">
                    {video.text}
                  </p>
                </div>
                <div>
                  {/* <button  className="rounded-full max-w-xl px-6 pr-5 py-5 bg-[#F69525] z-[1020]">
          <img src={play}  className="fill-white" alt="play" />
        </button> */}
                  {/* <InstagramReelModal reelUrl={video.url} /> */}
                  <a href={video.url} target="_blank" className="button-link">
                    <img src={play} className="fill-white" alt="play" />
                  </a>

                </div>
              </div>
            </div>
          </div>
        ))}

      </div>
      {/* <div className="space-y-8 px-8 lg:px-16 mb-32 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
          {[
            videoImage1,
            videoImage2,
            videoImage3,
            videoImage4,
            videoImage5,
            videoImage6,
          ].map((videoSrc, index) => (
            <div
              key={index}
              className="  bg-zinc-100 border border-gray-200 rounded-lg shadow "
            >
              <img className="rounded-t-lg p-4 pb-0" src={videoSrc} alt="" />
              <div className="p-5">
                <h5 className="mb-2 text-xl   text-neutral-700 text-[25px] font-bold  tracking-tight ">
                  Noteworthy technology acquisitions 2021
                </h5>
                <div className="flex justify-between">
                  <div className="w-2/3">
                    <p className="mb-3 text-[15px]  text-neutral-400 lg:text-xl font-medium  ">
                      Here are the biggest enterprise technology acquisitions of
                      2021 so far, in reverse chronological order.
                    </p>
                  </div>
                  <div>
                    <button className="rounded-full max-w-xl px-6 pr-5 py-5  bg-[#F69525] z-[1020]">
                      <img src={play} className="fill-white" alt="play" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div> */}
    </>
  );
};

export default LearMoreRecycleable;
