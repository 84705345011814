
import homeOrder from "../../assets/images/homeOrder.png";
import companyOrder from "../../assets/images/companyOrder.png";
import schoolOrder from "../../assets/images/schoolOrder.png";
import contractOrder from "../../assets/images/contractOrder.png";
import partnerOrder from "../../assets/images/partnerOrder.png";
import teamOrder from "../../assets/images/teamOrder.png";
import eventOrder from "../../assets/images/eventOrder.png";
import { useNavigate } from "react-router-dom";
import { telephone, email, map } from "assets/main";
import { savePartner } from "assets/firebase";
import { getBecomePartnerPage } from "assets/firebase";
import React, { useEffect, useState } from "react";
import Lottie from 'lottie-react';
import spinningArrowsAnimation from "assets/lottie/animation.json";
import { collection } from "firebase/firestore";
//import { db } from '/firebase'; 
const BecomePartner = () => {
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useNavigate();
  const initialFormData = {
    questions: [],
    servicesFor: {},
    enterpriseName: '',
    name: '',
    phone: '',
    email: '',
    preferedContact: '',
    additionalRequirments: '',
    notify:'true'
  };
  const [formData, setFormData] = useState(initialFormData);


  const handleChange = (event) => {
    /*   const { name, value, type, checked } = event.target;
    
       if (type === 'checkbox' || type==='radio') {
        // If it's a question, update questions with the new question and answer
        setFormData(prevState => ({
          ...prevState,
          questions: [
              ...prevState.questions,
              {
                  ques: name,
                  ans: value
              }
          ]
      })); */
    const { name, value, type, checked } = event.target;

    if (type === 'checkbox' || type === 'radio') {
      setFormData(prevState => {
        // Find the index of the question in the array
        const questionIndex = prevState.questions.findIndex(question => question.ques === name);

        // If the question exists, update the answer
        if (questionIndex !== -1) {
          return {
            ...prevState,
            questions: prevState.questions.map((question, index) => {
              if (index === questionIndex) {
                return {
                  ...question,
                  ans: [...question.ans, value] // assuming ans is an array to hold multiple answers
                };
              } else {
                return question;
              }
            })
          };
        } else {
          // If the question doesn't exist, add a new question
          return {
            ...prevState,
            questions: [
              ...prevState.questions,
              {
                ques: name,
                ans: [value] // assuming ans is an array to hold multiple answers
              }
            ]
          };
        }
      });
    }

    else {
      // If it's any other field, update that field
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log('zee' + JSON.stringify(formData));
    var result= await savePartner(formData);
    if (result.success) {
      setFormData(initialFormData);
      history('/thankYou');
    }
  };

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {
        const data = await getBecomePartnerPage();
        const sortedData = data.sort((a, b) => a.order - b.order);
      
        setPageData(sortedData);
        setLoading(false);

      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);


  if (loading) {
    return (
      <div className="w-[200px] m-auto mt-5 mb-5">

        <div>
          <Lottie animationData={spinningArrowsAnimation} />
        </div>
      </div>
    );
  }
  //console.log('becompartner' + JSON.stringify(pageData) );






  /*  const handleChange = (e) => {
     const { name, value, type, checked } = e.target;
     if (type === 'checkbox' && (name == 'company' || name == 'school' || name == 'home')) {
       // If it's a checkbox, update servicesFor based on the checked checkboxes
       const updatedServicesFor = { ...formData.servicesFor };
       updatedServicesFor[name] = checked;
       setFormData({ ...formData, servicesFor: updatedServicesFor });
     } else if (type === 'checkbox' && (name == 'contract' || name == 'partner' || name == 'team' || name == 'event')) {
   
       const updatedotherServices = { ...formData.otherServices };
       updatedotherServices[name] = checked;
       setFormData({ ...formData, otherServices: updatedotherServices });
     } else if (type === 'checkbox' && (name == 'op1' || name == 'op2' || name == 'op3' || name == 'op4')) {
   
       const updatedbuyOptions = { ...formData.buyOptions };
       updatedbuyOptions[name] = checked;
       setFormData({ ...formData, buyOptions: updatedbuyOptions });
     }else {
       // For other inputs, update the form data as usual
       console.log(value);
       setFormData({ ...formData, [name]: value });
     }
 
   };
 
   const handleFormSubmit = async (e) => {
     e.preventDefault();
 
     const result = await savePartner(formData);
     if (result.success) {
       //window.alert('Thank you for reaching us!');
       history('/thankYou');
       // console.log('Form submitted successfully. Document ID:', result.docId);
       setFormData({ name: '', email: '', phone: '', enterpriseName: '', preferedContact: '', servicesFor: '', buyOptions:'',otherServices:'' }); // Reset form data
       //setCheckboxes({ home: false, school: false, company: false });
     } else {
       console.error('Error submitting form:', result.error);
     }
   }; */
  return (
    <>
      {/* Order Request Form */}
      <form onSubmit={handleFormSubmit}>
        <div className="max-w-6xl mx-auto rounded lg:p-8 p-2 shadow-md bg-[#EFEFEF] ">
          <div className="space-y-12 max-w-6xl mx-auto rounded lg:px-20 px-8 py-16 shadow-md bg-[#fff] rounded-[50px] lg:mt-[-200px] mt-[-125px]">
            <div className="border-b border-gray-900/10 lg:pb-12 pb-8">
              <h2 className="text-2xl font-bold leading-normal text-[#323232] lg:mt-4 mt-[-14]">Order Request</h2>
              <p className="lg:mt-6 mt-4 lg:text-md lg:w-auto w-[300px] font-medium leading-normal text-[#B7B7B7]">
                After you fill out this order request, we will contact you after we go over the details. If you would like faster service and direct information, Contact us at 34071010 or recycle@ebincompany.com
              </p>
            </div>
            <dir>

            </dir>
            <div className="border-b border-gray-900/10 pb-10">
              {pageData.map((q, index) => (
                <div key={index} >
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 17 17" fill="none" className="absolute mt-1.5">
                    <circle cx="8.5" cy="8.5" r="8.5" fill="#F69525" />
                  </svg>
                  <h2 className="lg:ml-10 ml-6 text-base font-semibold leading-7 text-[#292929]">{q.question} <span className="text-[25px] text-[#F00] font-medium">*</span></h2>
                  <div className="lg:ml-10 ml-7 mt-10 space-y-10">
                    <fieldset>

                      <div className="mt-6 lg:grid grid-cols-6 gap-y-8 sm:grid-cols-3">
                        {q.answers.map((ans, ansIndex) => (
                          <div key={ansIndex} className="relative flex gap-x-3 mb-8">
                            <div className="flex h-6 items-center">
                              {q.type == 'checkbox' && 
                                <input
                                id={`answer-${index}-${ansIndex}`}
                                name={q.question}
                                checked={formData.servicesFor[`answer-${index}-${ansIndex}`]}
                                onChange={handleChange}
                                type="checkbox"
                                value={ans.answer}
                                className="h-4 w-4 rounded border-black text-[#5B5B5B] focus:ring-[#5B5B5B] bg-[#5B5B5B]"
                              />
                              }
                              {q.type == 'radio' && <input
                                id={`answer-${index}-${ansIndex}`}
                                name={q.question}
                                checked={formData.servicesFor[`answer-${index}-${ansIndex}`]}
                                onChange={handleChange}
                                type="radio"
                                value={ans.answer}
                                className="h-4 w-4 rounded border-black text-[#5B5B5B] focus:ring-[#5B5B5B] bg-[#5B5B5B]"
                              />}
                              {q.type == 'text' && 
                              
                              <input
                                id={`answer-${index}-${ansIndex}`}
                                type="text"
                                name={q.question}
                                onChange={handleChange}
                                placeholder="Answer"
                                value={ans.answer}
                                className="col-span-full px-6 bg-[#EFEFEF] block w-full rounded-full border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />}
                            </div>
                            {q.type != 'text' && (
                              <>
                                <div className="">
                                  <img className="absolute w-7 ml-3" src={ans.answerIcon ?? homeOrder} />
                                  <span className="block bg-[#EFEFEF] border-2 mt-[-12px] p-6 border-[#EFEFEF] rounded-full"></span>
                                </div>
                                <div className="text-sm leading-6">
                                  <label htmlFor={`answer-${index}-${ansIndex}`} className="font-medium text-[16px] text-[#000]">
                                    {ans.answer}
                                  </label>
                                </div>
                              </>
                            )}


                          </div>
                        ))}


                      </div>
                    </fieldset>
                  </div>
                </div>

              ))}


            </div>
            <div className="pb-12">
              <div className="block mt-10 lg:grid grid-cols-6 gap-x-10 gap-y-8 sm:grid-cols-5">
                <div className="sm:col-span-2 pb-8">
                  <label htmlFor="first-name" className="block text-[16px] font-medium leading-6 text-[#4C4C4C]">
                    Enterprise Name <span className="text-[25px] text-[#F00]">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="enterpriseName"
                      type="text"
                      name="enterpriseName"
                      value={formData.enterpriseName}
                      onChange={handleChange}
                      placeholder="Enterprise Name"
                      autoComplete="given-name"
                      className="px-6 bg-[#EFEFEF] block w-full rounded-full border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2 lg:w-[350px] lg:ml-28 pb-8">
                  <label htmlFor="last-name" className="block text-[16px] font-medium leading-6 text-[#4C4C4C]">
                    Your Name <span className="text-[25px] text-[#F00]">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      required
                      name="name"
                      id="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Name"
                      autoComplete="family-name"
                      className="px-6 bg-[#EFEFEF] block w-full rounded-full border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2 pb-8">
                  <label htmlFor="phone" className="block text-[16px] font-medium leading-6 text-[#4C4C4C]">
                    Phone Number <span className="text-[25px] text-[#F00]">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="phone"
                      name="phone"
                      required
                      value={formData.phone}
                      onChange={handleChange}
                      type="phone"
                      placeholder="Phone"
                      autoComplete="phone"
                      className="px-6 bg-[#EFEFEF] block w-full rounded-full border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2 lg:w-[350px] lg:ml-28">
                  <label htmlFor="email" className="block text-[16px] font-medium leading-6 text-[#4C4C4C]">
                    Email Address <span className="text-[25px] text-[#F00]">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      required
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email"
                      autoComplete="email"
                      className="px-6 bg-[#EFEFEF] block w-full rounded-full border-0 py-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />

                  </div>
                </div>
              </div>

              <div className="pb-12 mt-10">
                <h2 className="text-base font-semibold leading-7 text-[#4C4C4C]">Prefered Contact Method <span className="text-[#F00]">*</span></h2>

                <div className="lg:mt-5 space-y-10">
                  <fieldset>
                    <div className="mt-6 space-y-6">
                      <div className="relative flex gap-x-3">
                        <div className="flex h-6 items-center">
                          <input
                            id="preferedContact"
                            name="preferedContact"
                            type="radio"
                            required
                            value="phone"
                            onChange={handleChange}
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                        </div>
                        <div className="text-sm leading-6">
                          <label htmlFor="phone" className="font-medium text-[#000] text-[16px]">
                            Phone
                          </label>
                        </div>
                      </div>
                      <div className="relative flex gap-x-3">
                        <div className="flex h-6 items-center">
                          <input
                            id="email"
                            name="preferedContact"
                            value="email"
                            onChange={handleChange}
                            type="radio"
                            className="h-4 w-4 rounded border-[#262161]-600 focus:ring-indigo-600"
                          />
                        </div>
                        <div className="text-sm leading-6">
                          <label htmlFor="email" className="font-medium text-[#000] text-[16px]">
                            Email
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="additionalRequirments" className="block text-[#16px] font-medium leading-6 text-[#4C4C4C]">
                  Any Additional Requirments
                </label>
                <div className="mt-2">
                  <input
                    id="additionalRequirments"
                    name="additionalRequirments"
                    type="text"
                    value={formData.additionalRequirments}
                    onChange={handleChange}
                    placeholder="Add here"
                    className="bg-[#EFEFEF] block w-full lg:rounded-full rounded-[25px] border-0 px-6 lg:py-6 py-40  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="mt-6 items-center flex justify-center">
              <button
                type="submit"
                className="rounded-full bg-[#F69525] pr-20 pl-8 lg:py-4 py-2 lg:text-xl font-semibold text-black"
              >
                Submit
                <span className="absolute lg:mt-[-12px] mt-[-6px] lg:ml-[24px] ml-[40px] bg-[#262161] border-2 lg:p-3.5 p-1.5 border-[#262161] rounded-full">
                  <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" /></svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default BecomePartner;
