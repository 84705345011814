import React from "react";

import {
  Eas,
  fast,
  monogram,
  offWhite,
  pool,
  raise,
  roman,
  spotify,
} from "assets/main";
const Partners = ({ homeData, loading }) => {
  if (loading) {
    return;
  }
  return (
    <>
      <div className="bg-white px-8  lg:px-[80px]]">
        <div className="bg-white text-center lg:text-center p-8 rounded-lg lg:p-[65px]">
          <div className="lg:w-2/4 lg:ml-[35%]">
            <div className="lg:w-[60%] lg:text-4xl text-2xl text-[#262161] font-extrabold text-center mt-[-10px] lg:mt-14 mb-79px ">
              {/* We work with the nest partners */}
              {homeData.component8.heading}
            </div>
          </div>
          <div className="m-auto lg:mt-10 mt-10 lg:w-[75%]" >
            {
              <div className="text-[#1a1a1a] lg:text-lg text-[12px] text-center leading-relaxed x">
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliqui */}
                {homeData.component8.sub_heading}
              </div>
            }
          </div>
          <div className="opacity-60 ml-[145px] sm:ml-[145px]">
            <div className="flex-row mt-2 lg:flex items-center justify-center grid  md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-14">
            {homeData.component8.image1 ? (
              <div className="col-span-3 lg:w-[200px] w-[120px] lg:ml-[-20px] ml-[-80px] mt-[20px] relative overflow-hidden">
                <img src={homeData.component8.image1} alt="Roman" />
              </div>
               ) : null}
             {homeData.component8.image2 ? (
             <div className="col-span-3 lg:w-[200px] w-[120px] lg:ml-[-20px] ml-[-80px] mt-[20px] relative overflow-hidden">
                <img className="" src={homeData.component8.image2} alt="Monogram" />
              </div>
              ) : null}
              {homeData.component8.image3 ? (
              <div className="col-span-3 lg:w-[200px] w-[120px] lg:ml-[-20px] ml-[-80px] mt-[20px] relative overflow-hidden">
                <img src={homeData.component8.image3} alt="Company" />
              </div>
               ) : null}
              {homeData.component8.image4 ? (
              <div className="col-span-3 lg:w-[200px] w-[120px] lg:ml-[-20px] ml-[-80px] mt-[20px] relative overflow-hidden">
                <img src={homeData.component8.image4} alt="Spotify" />
              </div>
              ) : null}
            </div>
            <div className="flex-row mt-[60px] lg:flex items-center justify-center grid  md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-14">
            {homeData.component8.image5 ? (
              <div className="col-span-3 lg:w-[200px] w-[120px] lg:ml-[-20px] ml-[-80px] mt-[20px] relative overflow-hidden">
                <img src={homeData.component8.image5} alt="Roman" />
              </div>
               ) : null}
               {homeData.component8.image6 ? (
              <div className="col-span-3 lg:w-[200px] w-[120px] lg:ml-[-20px] ml-[-80px] mt-[20px] relative overflow-hidden">
                <img className="" src={homeData.component8.image6} alt="Monogram" />
              </div>
              ) : null}
              {homeData.component8.image7 ? (
              <div className="col-span-3 lg:w-[200px] w-[120px] lg:ml-[-20px] ml-[-80px] mt-[20px] relative overflow-hidden">
                <img src={homeData.component8.image7} alt="Company" />
              </div>
              ) : null}
              {homeData.component8.image8 ? (
              <div className="col-span-3 lg:w-[200px] w-[120px] lg:ml-[-20px] ml-[-80px] mt-[20px] relative overflow-hidden">
                <img src={homeData.component8.image8} alt="Spotify" />
              </div>
              ) : null}
            </div>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners;
