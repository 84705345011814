import React, { useEffect } from 'react';

const ZohoChat = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'zsiqchat';
    script.innerHTML = `
      var $zoho=$zoho || {};
      $zoho.salesiq = $zoho.salesiq || {
        widgetcode: "siq127211719030f1d9f14e4d24421b36de755551a5a63aa94d74d332e4523ed0cd",
        values:{},
        ready:function(){}
      };
      var d=document;
      var s=d.createElement("script");
      s.type="text/javascript";
      s.id="zsiqscript";
      s.defer=true;
      s.src="https://salesiq.zohopublic.com/widget";
      var t=d.getElementsByTagName("script")[0];
      t.parentNode.insertBefore(s,t);
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // Since this component only handles script integration, return null
};

export default ZohoChat;